import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactModel } from 'src/app/models/contact.model';
import { MessageModel } from 'src/app/models/message.model';
import { ContactService } from 'src/app/pages/user-account/services/contact.service';
import { MessageService } from 'src/app/pages/user-account/services/message.service';

@Component({
  selector: 'app-footer-mobile',
  templateUrl: './footer-mobile.component.html',
  styleUrls: ['./footer-mobile.component.scss']
})
export class FooterMobileComponent implements OnInit {
  ariaExpanded = false;
  collapseFooterOne = false;
  collapseFooterTwo = false;
  collapseFooterThree = false;
  contact: ContactModel = {};
  message: MessageModel = {};
  Form: FormGroup;
  date=new Date().getFullYear();
  constructor(private contactService: ContactService, private messageService: MessageService, private fb: FormBuilder) { }

  ngOnInit() {
    this.contactService.GetContact()
    .subscribe(data => {
      this.contact = data;
    });
    this.message = new MessageModel();
    this.Form = this.createForm();
  }
  createForm(): FormGroup {
    return this.fb.group({
      id: [this.message.id],
      email: [this.message.email, Validators.required],
      isView: [this.message.isView],
      mobile: [this.message.mobile],
      name: [this.message.name],
      subject: [this.message.subject],
      theMessage: [this.message.theMessage],
    });
  }
  onSubmit() {
    this.message = this.Form.getRawValue();
    this.messageService.CreateMessage(this.message)
    .subscribe(data => {
      if ( data === true ) {
      }
    });
  }

}
