import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { CompleteFalsehoodComponent } from 'src/app/components/complete-falsehood/complete-falsehood.component';
import { RegistrationModalComponent } from 'src/app/components/registration-modal/registration-modal.component';
import { KatoraService } from 'src/app/services/katora.service';
import { BoxCartModel } from '../../user-account/models/box-cart.model';
import { CartModel } from '../../user-account/models/Cart.model';
import { CartService } from '../../user-account/services/cart.service';

@Component({
  selector: 'app-cart-one',
  templateUrl: './cart-one.component.html',
  styleUrls: ['./cart-one.component.scss']
})
export class CartOneComponent implements OnInit {
  public productAddedToCart: CartModel[] = [];
  public boxesAddedToCart: BoxCartModel[] = [];
  public openForm = false;
  public productTotal = 0;
  count = 0;
  isLogin = false;
  public current_language;
  constructor(
    private katoraService : KatoraService,
    private cartService: CartService,
    public toaster: ToastrService,
    public translate: TranslateService,
    public router:Router,
    private matDialog : MatDialog,

    ) { 
    this.current_language = localStorage.getItem('lang');
     }
    ngOnInit() {
      this.count = this.katoraService.getCartCount();
      this.productAddedToCart = this.katoraService.getProductFromCart();
      this.boxesAddedToCart = this.katoraService.getboxesFromCart();
      this.productAddedToCart.forEach(element => {
        element.productPrice = element.totalPrice;
      });
      this.openForm = true;  
      const token = localStorage.getItem('token');
      if(token != null){
        this.isLogin = true;
      }
    }
    ngAfterContentChecked(){
      // this.productAddedToCart = this.katoraService.getProductFromCart();    
      this.productTotal =  + this.katoraService.calculateTotal().toFixed(2);
    }
    
    addQuantity(product: any){
      const i = this.productAddedToCart.indexOf(product);
      if (i !== -1) {
        this.katoraService.onAddQuantity(this.productAddedToCart[i])
        this.productAddedToCart[i].quantity ++;
      }
  
    }
    removeQuantity(product: any){
      const i = this.productAddedToCart.indexOf(product);
      if (i !== -1) {
        this.katoraService.onRemoveQuantity(this.productAddedToCart[i])
        this.productAddedToCart[i].quantity --;
      }
    }
  
    addBoxQuantity(product: any){
      const i = this.boxesAddedToCart.indexOf(product);
      if (i !== -1) {
        this.katoraService.onAddBoxQuantity(this.boxesAddedToCart[i])
        this.boxesAddedToCart[i].quantity ++;
      }
  
    }
    removeBoxQuantity(product: any){
      const i = this.boxesAddedToCart.indexOf(product);
      if (i !== -1) {
        this.katoraService.onRemoveBoxQuantity(this.boxesAddedToCart[i])
        this.boxesAddedToCart[i].quantity --;
      }
    }
  
    removeProduct(product: any){
      const i = this.productAddedToCart.indexOf(product);
      this.katoraService.removeProductFromCart(this.productAddedToCart[i]);
      this.cartService.delete(this.productAddedToCart[i])
    .subscribe(data =>{
      this.ngOnInit();
    });
    }
    
    removeBoxFromCart(product: any){
      const i = this.boxesAddedToCart.indexOf(product);
      this.katoraService.removeBoxFromCart(this.boxesAddedToCart[i]);
      this.cartService.delete(this.boxesAddedToCart[i])
    .subscribe(data =>{
      this.ngOnInit();
    });
    }
    
  // ngOnDestroy(): void {
  //   this.productAddedToCart.forEach(element => {
  //     this.cartService.put(element)
  //     .subscribe(result => {
  //     });
  //   });
  // }
  // ngOnInit() {
 
  //   this.cartService.GetForCurrentUser()
  //   .subscribe(data => {
  //     this.productAddedToCart = data;
  //     if(this.productAddedToCart.length > 0)
  //    {
  //     this.openForm = true;
  //     this.productAddedToCart.forEach(element => {
  //     if (element.quantity <= 0) {
  //       element.quantity = 1;
  //     }
  //     if(element.totalPrice == null) {
  //       element.totalPrice = 0;
  //     }
  //     this.productTotal += element.totalPrice * element.quantity;
  //   });
  // }
  // else{
  //   this.openForm = true;
  // }
  //   });
  //   // this.productAddedToCart = this.katoraService.getProductFromCart();
  // }
  // // ngAfterContentChecked(){
  // //   this.productAddedToCart = this.katoraService.getProductFromCart();
  // //   this.productTotal =  this.katoraService.calculateTotal().toFixed(2);
  // // }
  // addQuantity(product: any) {
  //   const i = this.productAddedToCart.indexOf(product);
  //   if (i !== -1) {
  //     this.productAddedToCart[i].quantity ++;
  //   }
  // }
  calcPrice(data){
    const i = this.productAddedToCart.indexOf(data);
    if (i !== -1) {
      this.katoraService.savePrice(this.productAddedToCart[i])
      this.productAddedToCart[i].totalPrice = data.totalPrice;
    }
  }
  // saveQuantity(product: any) {
  //   const i = this.productAddedToCart.indexOf(product);
  //   if (i !== -1) {
  //     this.cartService.put(this.productAddedToCart[i])
  //     .subscribe(result => {
  //       this.toaster.success('Edit Quantity Done Success');
  //       this.productTotal = 0 ;
  //       this.productAddedToCart.forEach(element => {
  //         this.productTotal += element.totalPrice * element.quantity;
  //       });
  //     });
  //   }
  // }
  // removeQuantity(product: any) {
  //   const i = this.productAddedToCart.indexOf(product);
  //   if (i !== -1) {
  //     this.productAddedToCart[i].quantity --;
  //   }
  // }
  // removeProduct(product: any) {
  //   const i = this.productAddedToCart.indexOf(product);
  //   if (i !== -1) {
  //     this.cartService.delete(this.productAddedToCart[i])
  //     .subscribe(result => {
  //       this.productAddedToCart.splice(i , 1);
  //     });
  //   }
  // }
  checkOut(){
    if(this.isLogin == true){
      this.router.navigate(['checkout'])
    }
    else{
      let dialogRef : MatDialogRef<CompleteFalsehoodComponent>;
      dialogRef =this.matDialog.open(CompleteFalsehoodComponent);
      dialogRef.afterClosed()
      .subscribe(x=>{
        if(x == 'Login'){
          this.router.navigate(['login'])
        }
        else if(x == 'Create Account'){
          this.router.navigate(['signup'])
        }
        else if(x == 'guest'){
          this.router.navigate(['checkout-as-a-guest'])
        }
        dialogRef.close();
      })
      // this.toaster.error("Please Login")
    }
  }
}
