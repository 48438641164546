import { Component, HostListener, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Slider2PhotoModel } from 'src/app/models/slider2-photo.model';
import { Slider2PhotoService } from 'src/app/services/slider2-photo.service';

@Component({
  selector: 'app-full-screen-banner',
  templateUrl: './full-screen-banner.component.html',
  styleUrls: ['./full-screen-banner.component.scss']
})
export class FullScreenBannerComponent implements OnInit {

  constructor(
    private slider2PhotoService: Slider2PhotoService,
    private sanitization: DomSanitizer,
  ) { }
  photo: Slider2PhotoModel = {};
  openSlider = false;
  safeImage = {};
    public getScreenWidth: any;
    public getScreenHeight: any;
      @HostListener('window:resize', ['$event'])
      onWindowResize() {
        this.getScreenWidth = window.innerWidth;
        this.getScreenHeight = window.innerHeight;
      }
  ngOnInit() {
    this.onWindowResize();
    this.slider2PhotoService.GetPhoto()
    .subscribe(photo => {
      this.photo = photo;

      if(this.photo.type == 1){
        this.openSlider = true;
        this.safeImage = this.getScreenWidth > 1024 ? this.sanitization.bypassSecurityTrustStyle(`url(${photo.imageUrl})`) : this.sanitization.bypassSecurityTrustStyle(`url(${photo.mobileImageUrl})`);
      }
      else if(this.photo.type == 2){
        this.openSlider = true;
      }
      else{
        this.openSlider = false;
      }
    });
  }

}
