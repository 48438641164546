import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { CheckoutModel } from '../../checkout/checkout.model';
import { CartService } from '../services/cart.service';
import pdfFonts from "pdfmake/build/vfs_fonts";  
import pdfMake from "pdfmake/build/pdfmake";  
import { DatePipe } from '@angular/common';
pdfMake.vfs = pdfFonts.pdfMake.vfs; 

class Product{    
  name: string;    
  price: number;    
  qty: number; 
  pricebefortax:number;
}    
class Invoice{    
  customerName: string;    
  address: string;    
  contactNo: number;    
  email: string;    
  products: Product[] = [];    
  additionalDetails: string;    
  orderNumber  : string;  
  tax : number;
  deleveryPrice : number;
  copenCode : number;
  balanceUsed : number;
  total:number;
  date:Date;
  constructor(){    
    // Initially one empty product row we will show     
    this.products.push(new Product());    
  }    
}  
@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  providers: [DatePipe]
})

export class OrderDetailComponent implements OnInit {
  Id = 0;
 constructor(
  private router:Router,
  private route: ActivatedRoute,
  private orderService:OrderService,
  private datePipe: DatePipe

 ){
  this.Id = + route.snapshot.paramMap.get('Id');
 }
 productTotalBeforeVat = 0;
 subtotalAfterDiscound = 0;
 deleveryPriceVat = 0;
 productTotal = 0;
 total = 0;
 couponCode = 0;
 qrCodeB64 = '';
    ngOnInit(): void {
      this.orderService.GetInvoiceByOrderId(this.Id)
      .subscribe(data => {
        this.invoice = data;
        this.productTotalBeforeVat = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2))/1.15;
        this.couponCode = this.invoice.copenCode;
        this.subtotalAfterDiscound = this.productTotalBeforeVat- this.invoice.copenCode;
        this.productTotal = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2));
        this.productTotal -=  this.invoice.copenCode;
        this.deleveryPriceVat = (this.invoice.deleveryPrice/100)* 15  ;
        this.total = (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.copenCode - this.invoice.balanceUsed;
        this.invoice.tax = 0;
        // this.invoice.products.forEach(element => {
        //   // var t =  (element.price/100)* 15
        //   element.pricebefortax = element.price/1.15;
        //   this.invoice.tax += element.price -element.pricebefortax
        // });
        this.productTotal = this.subtotalAfterDiscound * 1.15;
        this.total = this.productTotal + this.invoice.deleveryPrice  + this.deleveryPriceVat - this.invoice.balanceUsed;
        var vat= this.productTotal / 1.15;
        this.invoice.tax = this.productTotal - vat;
        localStorage.removeItem('cartProducts');
        var sellerNameBuf=this.getTLVForValue("1","International Chocolate Trading Company");
        // var sellerNameBuf=this.getTLVForValue("1","شركة الشوكولاتة العالمية التجارية");
        var vatRegistrationNameBuf = this.getTLVForValue("2","310387548200003");
        var timeBuf = this.getTLVForValue("3",this.datePipe.transform(this.invoice.date, 'yyyy-MM-dd, h:mm a'));
        var totalBuf = this.getTLVForValue("4",this.total.toFixed(2));
            var totalvat = this.invoice.tax + this.deleveryPriceVat;
            var totalVATBuf = this.getTLVForValue("5",totalvat.toFixed(2));
        var tagBufsArray=[sellerNameBuf,vatRegistrationNameBuf,timeBuf,totalBuf,totalVATBuf];
         var Buffer = require('buffer/').Buffer
        var qrCodeBuf = Buffer.concat(tagBufsArray);
        this.qrCodeB64 = qrCodeBuf.toString('base64');
      })
    }
  invoice = new Invoice(); 
  getTLVForValue(tagNum,tagValue){
    var Buffer = require('buffer/').Buffer
    var tagBuf=Buffer.from([tagNum],'utf8');
    var tagValueLenBuf=Buffer.from([tagValue.length],'utf8');
    var tagValueBuf=Buffer.from(tagValue,'utf8');
    var bufArray = [tagBuf,tagValueLenBuf,tagValueBuf]
    return Buffer.concat(bufArray);
  }
  generatePDF(action = 'open') {
     
    let docDefinition = {
      content: [
        {
          text: 'Laderach',
          fontSize: 16,
          alignment: 'center',
          color: '#047886'
        },
        {
          text: 'INVOICE',
          fontSize: 20,
          bold: true,
          alignment: 'center',
          decoration: 'underline',
          color: 'skyblue'
        },
        {
          text: 'Customer Details',
          style: 'sectionHeader'
        },
        
        
        {
          columns: [
            [
              {
                text: this.invoice.customerName,
                bold:true
              },
              { text: this.invoice.address },
              { text: this.invoice.email },
              { text: this.invoice.contactNo }
            ],
            [
              {
                text: `Date: ${new Date(this.invoice.date).toLocaleString()}`,
                alignment: 'right'
              }
            ]
          ]
        },
        {
          text: 'Order Details',
          style: 'sectionHeader'
        },
        {
          text: 'Order Number : #' + this.invoice.orderNumber
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto'],
            body: [
              ['Coupon Code', this.invoice.copenCode],
              ['TAX', this.invoice.tax],
              ['Delevery Price', this.invoice.deleveryPrice],
              ['Balance', this.invoice.balanceUsed]
            ]
          }
        },
        {
          text: 'Products Details',
          style: 'sectionHeader'
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              ['Product', 'Price', 'Quantity', 'Amount'],
              ...this.invoice.products.map(p => ([p.name, p.price, p.qty, (p.price*p.qty).toFixed(2)])),
              [{text: 'Total Amount', colSpan: 3}, {}, {}, (+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice + this.invoice.tax - this.invoice.copenCode - this.invoice.balanceUsed]
            ]
          }
        },
        {
          text: 'QR',
          style: 'sectionHeader'
        },
        {
          columns: [
            [{ qr: `
            Order No : #${this.invoice.orderNumber},
            Name : ${this.invoice.customerName},
            Total Price : ${(+this.invoice.products.reduce((sum, p)=> sum + (p.qty * p.price), 0).toFixed(2)) + this.invoice.deleveryPrice + this.invoice.tax - this.invoice.copenCode - this.invoice.balanceUsed},
            Email : ${this.invoice.email},
            Phone : ${this.invoice.contactNo}
            `, fit: '150'
             
          }],
            [{ text: 'Signature', alignment: 'right', italics: true}],
          ]
        },
        // {
        //   text: 'Terms and Conditions',
        //   style: 'sectionHeader'
        // },
        // {
        //     ul: [
        //       'Order can be return in max 10 days.',
        //       'Warrenty of the product will be subject to the manufacturer terms and conditions.',
        //       'This is system generated invoice.',
        //     ],
        // }
      ],
      styles: {
        sectionHeader: {
          bold: true,
          decoration: 'underline',
          fontSize: 14,
          margin: [0, 15,0, 15]          
        }
      }
    };

    if(action==='download'){
      pdfMake.createPdf(docDefinition).download();
    }else if(action === 'print'){
      pdfMake.createPdf(docDefinition).print();      
    }else{
      pdfMake.createPdf(docDefinition).open();      
    }

  }

  addProduct(){
     
    this.invoice.products.push(new Product());
  } 
}
