import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ProductModel } from './models/Product.model';
import { ProductWithDetailsModel } from './models/product-with-details.model';
import { SearchModel } from './models/search.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {

  }
  GetAllForCorpateGift(): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetAllForCorpateGift`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getAll(page: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetAll/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetProductsForUser(CategoryId: number, page: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetProductsForUser/${CategoryId}/${page}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByCategoryIdForUser(CategoryId: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetByCategoryIdForUser/${CategoryId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetLasted(): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetLasted`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Product/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountForCategory(categoryId : number): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Product/GetCountForCategory/${categoryId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCountByCategoryId(categoryId: number,CityId:number): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Product/GetCountByCategoryId/${categoryId}/${CityId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getByBranchId(id: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetByBranchId/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetNotInThisBranch(id: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetNotInThisBranch/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getByDishDesignId(id: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetByDishDesignId/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetNotInThisDishDesign(id: number): Observable<ProductModel[]> {
    return this.http
      .get<ProductModel[]>(`${this.baseUrl}/Product/GetNotInThisDishDesign/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createProduct(payload: ProductWithDetailsModel): Observable<boolean> {
    return this.http
      .post<boolean>(`${this.baseUrl}/Product/Insert`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  put(payload: ProductWithDetailsModel): Observable<boolean> {
    return this.http
      .put<boolean>(`${this.baseUrl}/Product/Update`, payload, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }

  delete(payload: ProductModel): Observable<boolean> {
    return this.http
      .delete<boolean>(`${this.baseUrl}/Product/Delete/${payload.productId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetById(id: number): Observable<ProductModel> {
      return this.http
      .get<ProductWithDetailsModel>(`${this.baseUrl}/Product/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
     }
  GetProductsForSearch(model: SearchModel , page: number): Observable<ProductModel[]> {
    return this.http
    .put<ProductModel[]>(`${this.baseUrl}/Product/GetProductsForSearch/${page}`, model , this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  ChickGqantity(ProductId: number,Quantity:number,CityId:number): Observable<boolean> {
    return this.http
    .get<boolean>(`${this.baseUrl}/Product/ChickGqantity/${ProductId}/${Quantity}/${CityId}`, this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
   }
}
