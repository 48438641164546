import { Component, AfterViewInit, ViewChild, ElementRef, OnInit, HostListener  } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ContactModel } from 'src/app/models/contact.model';
import { MessageModel } from 'src/app/models/message.model';
import { ContactService } from '../../user-account/services/contact.service';
import { MessageService } from '../../user-account/services/message.service';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { DomSanitizer } from '@angular/platform-browser';
import { StoreLocationService } from 'src/app/services/store-location.service';
import { StoreLocationModel } from 'src/app/models/store-location.model';

@Component({
  selector: 'app-contact-one',
  templateUrl: './contact-one.component.html',
  styleUrls: ['./contact-one.component.scss']
})
export class ContactOneComponent implements AfterViewInit , OnInit {
  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  Contact: ContactModel = {};
  locationObject: StoreLocationModel = {};
  message: MessageModel = {};
  Form: FormGroup;
  map: google.maps.Map;
  lat = 40.73061;
  lng = -73.935242;
  coordinates = new google.maps.LatLng(this.lat, this.lng);
  current_language = 'ar';
  mapOptions: google.maps.MapOptions = {
    center: this.coordinates,
    zoom: 8
  };
  marker = new google.maps.Marker({
    position: this.coordinates,
    map: this.map,
    title: 'Hello World!'
  });
    public getScreenWidth: any;
    public getScreenHeight: any;
      @HostListener('window:resize', ['$event'])
      onWindowResize() {
        this.getScreenWidth = window.innerWidth;
        
        this.getScreenHeight = window.innerHeight;
      }
  constructor(
    private contactService: ContactService,
     private messageService: MessageService, 
     private locationservice:StoreLocationService,
     private sanitization: DomSanitizer,
     private fb: FormBuilder, 
     public toaster: ToastrService,
     ) { this.current_language = localStorage.getItem('lang'); }
     slideConfigCarousel = {
      "slidesToShow": 1,
      "slidesToScroll": 1,
      "dots": false,
      "infinite": true,
      "arrows" : false,
  
      "autoplay":true,
      "autoplaySpeed":3000,
      "speed":600,
      
      "pauseOnHover":false,
  
      "pauseOnDotsHover":true,
      // "cssEase":'linear',
      //"fade":true,
      // "draggable":false,
      // "prevArrow":'<button class="PrevArrow"></button>',
      // "nextArrow":'<button class="NextArrow"></button>', 
      
      responsive: [
            {
        breakpoint: 1024,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            with:100,
          }
        },
        {
        breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            with:100,
          }
        },
        {
        breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  
    };
     @ViewChild('slickModal', {static: false}) slickModal: SlickCarouselComponent;
     next() {
      this.slickModal.slickNext();
    }
    
    prev() {
      this.slickModal.slickPrev();
    }
  createForm(): FormGroup{
    return this.fb.group({
      id: [this.message.id],
      email: [this.message.email, Validators.required],
      isView: [this.message.isView],
      mobile: [this.message.mobile, Validators.required],
      name: [this.message.name, Validators.required],
      subject: [this.message.subject, Validators.required],
      theMessage: [this.message.theMessage, Validators.required],
    });
  }
  ngOnInit(): void {
    this.onWindowResize();
   this.contactService.GetContact()
   .subscribe(data => {
     this.Contact = data;
     this.Contact.safeImage = "";
     if(this.getScreenWidth > 1024)
     {

       this.Contact.safeImage =   this.sanitization.bypassSecurityTrustStyle(`url(${this.Contact.imageUrl})`) ;
     }
     else{
      this.Contact.safeImage =  this.sanitization.bypassSecurityTrustStyle(`url(${this.Contact.mobileImageUrl})`)
     }
   });
   this.locationservice.getIsMainLocation().subscribe(data=>{
    this.locationObject=data;

   })
   this.message = new MessageModel();
   this.Form = this.createForm();
  }
  transform(url) {
    return this.sanitization.bypassSecurityTrustResourceUrl(url+"&output=embed");
}
  SendMessage(): void{
    this.message = this.Form.getRawValue();
    this.messageService.CreateMessage(this.message)
    .subscribe(data => {
      if(data === true) {
        this.toaster.success('The Message Send Successfly');
        this.message = new MessageModel();
        this.Form = this.createForm();
      }
    });
  }
  ngAfterViewInit() {
    this.mapInitializer();
  }
  mapInitializer() {
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.marker.setMap(this.map);
  }
}
