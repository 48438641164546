import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { checkSoudi, emailValidator, matchingPasswords, phoneValidator } from 'src/app/core/utils/app-validators';
import { CityService } from '../city.service';
import { ConfirmCodeComponent } from '../confirm-code/confirm-code.component';
import { CityModel } from '../model/city.model';
import { RegionModel } from '../model/restion.model';
import { UserRegistration } from '../model/user-registration';
import { SessionService } from '../session.service';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  public current_language;
  registerForm: FormGroup;
  Code = 0;
  cities: CityModel[] = [];
  cityId = 0;
  resions: RegionModel[] = [];
  user: UserRegistration = {};
  openForm = false;
  constructor(
    public translate: TranslateService,
    private UserService: SessionService,
    public toaster: ToastrService,
    private router: Router ,
    private cityService: CityService,
    private formBuilder: FormBuilder,
    ) { 
      this.current_language = localStorage.getItem('lang');
    }
  signUp(value) {
    value.userName = value.email;
    this.user = value;
    this.UserService.register(value)
    .subscribe(data => {
     if (data === 'Phone Used' || data === 'Email Used') {
          this.toaster.warning('You already have an account');
        }
        else{
          localStorage.setItem('token', data.token);
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.router.navigate(['']);
          this.UserService.loggedIn = true;
          this.toaster.success('Welcome');
        }
     },
       error => {
          this.toaster.error('This is not good!', 'You already have an account');
        });
 }


  ngOnInit() {
    this.cityService.getAll(0)
    .subscribe(data => {
      this.cities = data;
    });
    this.user = new UserRegistration();
    this.registerForm = this.createForm();
    // this.registerForm = this.formBuilder.group({
    //   name : [this.user.name, Validators.compose([Validators.required, Validators.minLength(3)])],
    //   email : [this.user.email , Validators.compose([Validators.required, emailValidator])],
    //   phone : [this.user.phone , Validators.required],
    //    password : [this.user.password , Validators.required],
    //   confirmPassword: [this.user.confirmPassword , Validators.required],
    //   reagionId : [this.user.reagionId , Validators.required],
    //   gender : [this.user.gender , Validators.required],
    //   address : [this.user.address , Validators.required],
    //   cityId : [this.user.cityId]
    // }, {validator: matchingPasswords('password', 'confirmPassword')});
    this.openForm = true;

  }

  createForm():FormGroup{
    return this.formBuilder.group({
      name : [this.user.name, Validators.compose([Validators.required, Validators.minLength(3)])],
      email : [this.user.email , Validators.compose([Validators.required, emailValidator])],
      phone : [this.user.phone ],
      password : [this.user.password , Validators.compose([Validators.required,  Validators.pattern('^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$')])],
      confirmPassword: [this.user.confirmPassword , Validators.required],
      reagionId : [this.user.reagionId , Validators.required],
      gender : [this.user.gender , Validators.required],
      address : [this.user.address , Validators.required],
      cityId : [this.user.cityId, Validators.required],
      isSaudi:[this.user.isSaudi]
    }, {validator: [matchingPasswords('password', 'confirmPassword')]});
    this.openForm = true;
    
 }
 changeCity(value) {
   this.cityService.GetResionsByCityId(value.cityId)
   .subscribe(data => {
     this.resions = data;
   });
 }
 IsSoudi = false;
 setNotSoudi($event){
   this.IsSoudi = false;
   checkSoudi('isSaudi','phone');

  //  if(this.IsSoudi == false){
  //    this.registerForm.controls['phone'].setValue('');
  //    this.registerForm.controls['phone'].setErrors({required:false,invalidPhone:false});
  //  }
  //  else{
  //   this.registerForm.controls['phone'].setErrors({required:true,invalidPhone:true});
  // }
 }
}
