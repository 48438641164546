import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CheckoutModel } from '../pages/checkout/checkout.model';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient) {
  }
  GetForCurrentUser(): Observable<CheckoutModel[]> {
    return this.http
      .get<CheckoutModel[]>(`${this.baseUrl}/Order/GetForCurrentUser`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  getById(id: number): Observable<CheckoutModel> {
    return this.http
      .get<CheckoutModel>(`${this.baseUrl}/Order/GetById/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetInvoiceByOrderId(OrderId: number): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/Order/GetInvoiceByOrderId/${OrderId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Order/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetStuats(id:number): Observable<any> {
    return this.http
      .get<any>(`${environment.DevPayUrl}/GetStuats/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  MyFatoorahPay(): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/MyFatoorahPayment/ExecutePayment`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetStuatsFromGetway(id:number): Observable<any> {
    var data = "entityId=8ac9a4c87da3fa5e017dad21639f59b7";
    var auth = "Bearer OGFjOWE0Yzk3ZGEzZjM0NDAxN2RhZDFmNmViMzU4ZTh8amI4ZUd5WjdCTQ=="
    var url = `https://oppwa.com/v1/checkouts/${id}/payment/${data}/${auth}`;
    return this.http
      .get<any>(url, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  UpdateAfterPay(id:number): Observable<any> {
    return this.http
      .get<any>(`${this.baseUrl}/Order/UpdateAfterPay/${id}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  
  createOrder(payload: CheckoutModel): Observable<number> {
        return this.http
          .post<number>(`${this.baseUrl}/Order/Insert`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 put(payload: CheckoutModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/Order/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 delete(payload: CheckoutModel): Observable<boolean> {
        return this.http
          .delete<boolean>(`${this.baseUrl}/Order/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }

      
      
}
