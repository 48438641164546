import { AfterViewInit, Component, ElementRef, OnInit, PipeTransform, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { StoreLocationModel } from 'src/app/models/store-location.model';
import { StoreLocationService } from 'src/app/services/store-location.service';

@Component({
    selector: 'store-locations',
    templateUrl: 'store-locations.component.html',
    styleUrls: ['store-locations.component.scss']
})
export class StoreLocationsComponent implements OnInit  {
  public current_language;
    storeLocations: StoreLocationModel[] = [];
    constructor(private storeLocationServeice: StoreLocationService, private sanitization: DomSanitizer){
      this.current_language = localStorage.getItem('lang');
    }
    transform(url) {
         return this.sanitization.bypassSecurityTrustResourceUrl(url+"&output=embed");
    }

    ngOnInit(): void {
        this.storeLocationServeice.getAll(0)
        .subscribe(data => {
            this.storeLocations = data;
            // this.storeLocations.forEach(element => {
            //   element.safeLocation = this.sanitization.bypassSecurityTrustUrl(element.location) ;
            // });
        });
    }
 

}
