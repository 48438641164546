import { CustomListService } from './../../../services/custom-list.service';
import { AfterViewInit, Component, HostListener, OnChanges, OnInit, ViewChild } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';
import { KatoraService } from 'src/app/services/katora.service';
import { CategoryModel } from '../../shop/models/category.model';
import { ProductModel } from '../../shop/models/Product.model';
import { ProductService } from '../../shop/product.service';
import { SliderInfoModel } from '../models/slider-info.model';
import { SliderInfoService } from '../slider-info.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomListModel } from 'src/app/models/custom-list.modle';
import { SessionService } from '../../session/session.service';
import { SlectCityComponent } from 'src/app/components/slect-city/slect-city.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { HomeTitlesService } from '../home.titles.service';
import { HomeTitlesModel } from '../models/home.titles.model';
import { element } from 'protractor';
@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit , AfterViewInit , OnChanges {
  public current_language;
  public getScreenWidth: any;
  public getScreenHeight: any;
    @HostListener('window:resize', ['$event'])
    onWindowResize() {
      this.getScreenWidth = window.innerWidth;
      this.getScreenHeight = window.innerHeight;
    }
  products: ProductModel[] = [];
  bestSellersProducts: ProductModel[] = [];
  listProducts: ProductModel[] = [];
  categories: CategoryModel[] = [];
  lists: CustomListModel[] = [];
  sliderInfo: SliderInfoModel[] = [];
  homeTitle: HomeTitlesModel[] = [];
  categoryId = 0;
  listId = 0;
  message = 'no';
  openForm = false;
  slideConfigCarousel = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    "arrows" : false,

    "autoplay":true,
    "autoplaySpeed":3000,
    "speed":600,
    
    "pauseOnHover":false,

    "pauseOnDotsHover":true,
    // "cssEase":'linear',
    //"fade":true,
    // "draggable":false,
    // "prevArrow":'<button class="PrevArrow"></button>',
    // "nextArrow":'<button class="NextArrow"></button>', 

    responsive: [
      {
      breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
      breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  slideTeamConfig = {
    slidesToShow: 4.30,
    slidesToScroll: 1,
    dots: false,
    infinite: false,
    arrows : true,
    
    responsive: [
      {
        breakpoint: 1023,
          settings: {
            slidesToShow: 3.30,
            slidesToScroll: 1
          }
        },
      {
      breakpoint: 768,
        settings: {
          slidesToShow: 2.30,
          slidesToScroll: 1
        }
      },
      {
      breakpoint: 678,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 400,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
   
    ]

  };
  slideConfigTabFeatured = {
    slidesToShow: 4,
    slidesToScroll: 4,
    dots: false,
    infinite: false,
    arrows : false,

    responsive: [
      {
      breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
      breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
      breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  constructor(
    private katoraService: KatoraService,
    private categoryService: CategoryService,
    private productService: ProductService,
    private sliderInfoService: SliderInfoService,
    private sanitization: DomSanitizer,
    private listService: CustomListService,
    private sessionService:SessionService,
    private matDialog : MatDialog,
    private homeTitleService:HomeTitlesService,

    ) {
      this.current_language = localStorage.getItem('lang');
    }
  ngOnInit() {
    this.onWindowResize();

    this.message = localStorage.getItem('message');
    if (this.message !== 'yes') {
      this.message = 'no';
    }
    this.sliderInfoService.getAll(0)
    .subscribe(data => {
      this.sliderInfo = data;
      this.sliderInfo.forEach(element => {
        element.safeImage = this.sanitization.bypassSecurityTrustStyle(`url(${element.imageUrl})`);
      });
this.homeTitleService.getAll(0).subscribe(data=>{
  this.homeTitle=data;
})
    if(this.sessionService.loggedIn == false){

    }

      this.categoryService.GetAllForHome()
      .subscribe(categories => {
        this.categories = categories;
        this.categories.forEach(element => {
          element.safecatImage=this.sanitization.bypassSecurityTrustStyle(`url(${element.imageUrl})`)
          if(element.hideFromHome == true){
            var i = this.categories.findIndex(x=>x.id == element.id);
            if(i > -1){
              this.categories.splice(i,1);
            }
          }
        });
        this.categoryId = this.categories[0].id;
        if(this.sessionService.loggedIn == true){
          this.productService.GetProductsForUser(this.categories[0].id , 0)
          .subscribe(products => {
            this.products = products;
            this.bestSellersProducts = products;
            this.products.forEach(element => {
              element.amount = 1;
              element.listId = 0;
            });
            this.bestSellersProducts.forEach(element => {
              element.amount = 1;
            });
          });
        }
        else{
          this.productService.GetProductsForUser(this.categories[0].id , 0)
          .subscribe(products => {
            this.products = products;
            this.bestSellersProducts = products;
            this.products.forEach(element => {
              element.amount = 1;
              element.listId = 0;
            });
            this.bestSellersProducts.forEach(element => {
              element.amount = 1;
            });
          });
        }
     
      });
      this.listService.getAll(0)
      .subscribe(data =>{
        this.lists = data;
        this.listId = this.lists[0].id;
        this.listService.getByListId(this.listId)
        .subscribe(product => {
          this.listProducts = product;
          this.listProducts.forEach(element => {
            element.listId = this.listId;
          });
        })
      });
    });
  }
  ngAfterViewInit() {
}
ngOnChanges(){
}
  updateProduct(id) {
    if(this.sessionService.loggedIn == true)
    {
      this.productService.GetProductsForUser(id , 0)
      .subscribe(products => {
        this.categoryId = id;
     
        this.products = products;
        this.products.forEach(element => {
          element.listId = 0;

        });
      });
    }
    else{
      this.productService.GetProductsForUser(id , 0)
      .subscribe(products => {
        this.categoryId = id;
        this.products = products;
        this.products.forEach(element => {
          element.listId = 0;

        });
      });
    }
   
  }
  updateList(id){
    this.listService.getByListId(id)
    .subscribe(products => {
      this.listId = id;
      this.listProducts = products;
      this.listProducts.forEach(element => {
        element.listId = this.listId;
      });
    });
  }
  onAddCart(product: any) {
    this.katoraService.addProductToCart(product);
  }
  slickInit(e) {
  }
 
  @ViewChild('slickModal', {static: false}) slickModal: SlickCarouselComponent;
  @ViewChild('slickModal2', {static: false}) slickModal2: SlickCarouselComponent;
  next() {
    this.slickModal2.slickNext();
  }
  
  prev() {
    this.slickModal2.slickPrev();
  }

 
}
