export class RegionModel{
    id?: number;
    nameAr?: string;
    nameEn?: string;
    nameFr?: string;
    isDeleted?: boolean;
    deliveryPrice?: number;
    constructor(region?: RegionModel) {
        region = region ? region : {};
        this.nameAr = region.nameAr ? region.nameAr : '' ;
        this.nameEn = region.nameEn ? region.nameEn : '' ;
        this.nameFr = region.nameFr ? region.nameFr : '' ;
        this.isDeleted = region.isDeleted ? region.isDeleted : false;
        this.deliveryPrice = region.deliveryPrice ? region.deliveryPrice : 0;
    }
}