import { ShippingAdderssModel } from './models/shipping-adderss.model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CouponCodeModel } from 'src/app/models/coupon-code.model';
import { CouponCodeService } from 'src/app/services/coupon-code.service';
import { OrderService } from 'src/app/services/order.service';

import { KatoraService } from '../../services/katora.service';
import { CityService } from '../session/city.service';
import { CityModel } from '../session/model/city.model';
import { RegionModel } from '../session/model/restion.model';
import { PurchaseCouponModel } from '../user-account/models/purchase-coupon.model';
import { CartService } from '../user-account/services/cart.service';
import { PurchaseCouponService } from '../user-account/services/purchase-coupon.service';
import { UserService } from '../user-account/services/user.service';
import { CheckoutModel } from './checkout.model';
import { BillingAddressModel } from './models/billing-adderss.model';
import { PaymentModel } from './models/payment.model';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CheckQuantityModel } from './models/check-quantity.model';
import { ShoppingAddressService } from '../user-account/services/shopping-address.service';
import { ShoppingAddressModel } from '../user-account/models/shopping-address.model';
import { DatePipe } from '@angular/common';
import { CurrentUserModel } from './models/current-user.odel';
import { ExecutePaymentRequestModel } from './models/my-fatoorah/ExecutePaymentRequest.model';
import { MyFatoorahService } from './my-fatoorah.service';
import { PaymentMethodsModel } from './models/my-fatoorah/payment-methods.model';


@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [DatePipe]

})
export class CheckoutComponent implements OnInit {
  checkOut: CheckoutModel = {};
  currentUser :CurrentUserModel =  {};
  public productAddedToCart;
  public productTotal = 0;
  public productTotalBeforeVat = 0;
  public subtotalAfterDiscound = 0;
  public total = 0;
  public couponCode = 0;
  public tax = 0;
  public deleveryPrice = 0;
  public deleveryPriceVat = 0;
  public products;
    public boxes;
    PurchaseCouponeCode = '';
  CouponeCode = '';
  cities: CityModel[] = [];
  sippingResions: RegionModel[] = [];
  billingResions: RegionModel[] = [];
  purchaseCoupon: PurchaseCouponModel = {} ;
  step = 'pills-shipping';
  shippingCity : CityModel = {};
  coupon: CouponCodeModel = {} ;
  userAccountAmount = 0 ;
  fastShipping = false;
  shippingForm:FormGroup;
  billingForm:FormGroup;
  paymentForm:FormGroup;
  shipping : ShippingAdderssModel = {};
  billing : BillingAddressModel = {};
  payment : PaymentModel = {};
  baseUrl = environment.DevPayUrl;
  public cardType;
  public current_language;
  checkQuantity:CheckQuantityModel={};
  myAddresses:ShoppingAddressModel [] = [];
  newAddress : ShoppingAddressModel ={};
  constructor(
    private katoraService: KatoraService,
    private cartService: CartService ,
    private purchaseCouponeService: PurchaseCouponService,
    private couponeCodeService: CouponCodeService,
    public toaster: ToastrService,
    private cityService: CityService,
    private orderService: OrderService,
    private router: Router,
    private userService:UserService,
    private fb:FormBuilder,
    public translate: TranslateService,
    private shippingAddressService:ShoppingAddressService,
    private datePipe: DatePipe,
    private myFatoorahServeice:MyFatoorahService
    ) {
    this.current_language = localStorage.getItem('lang');
    if(localStorage.getItem('token') && localStorage.getItem('token').length > 0){
      this.currentUser =  JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    }
  }
  shippingAddressId = 0;
  chooseShippingAddress(){
    var i = this.myAddresses.findIndex(x=>x.id == this.shippingAddressId);
    if(i > -1){
      this.shipping.shippingAddressAddress = this.myAddresses[i].address;
      this.shipping.shippingAddressCity = this.myAddresses[i].city;
      this.shipping.shippingAddressCityId = this.myAddresses[i].cityId;
      this.shipping.shippingAddressCompanyName = this.myAddresses[i].companyName;
      this.shipping.shippingAddressFirstName = this.myAddresses[i].firstName;
      this.shipping.shippingAddressLastName = this.myAddresses[i].lastName;
      this.shipping.shippingAddressPhone = this.myAddresses[i].phone;
      this.shipping.shippingAddressRegionId = this.myAddresses[i].resionId;
      this.shippingForm = this.CreateShippingAddressForm();
      this.chooseShippingResions();
      this.setDeleveryPrice();
    }
  }
  isLogin = false;
  ngOnInit() {
    const token = localStorage.getItem('token');
    if(token != null){
      this.isLogin = true;
    }
    if(this.isLogin == true){
      this.shippingAddressService.GetForCurrentUser()
      .subscribe(addresses => {
        this.myAddresses = addresses;
      });
    }
    this.products = this.katoraService.getProductFromCart();
      this.boxes = this.katoraService.getboxesFromCart();
      this.productTotal = 0 ;
    this.products.forEach(element => {
      this.productTotal += (element.totalPrice * element.quantity) ;
      // this.tax += (this.productTotal*100/115)* 0.15;
      // this.tax += (this.productTotal/100)* 15;
      // this.productTotalBeforeVat +=(element.totalPrice * element.quantity) ;
    });
    this.boxes.forEach(element => {
      this.productTotal += (element.totalPrice * element.quantity) ;
      // this.tax += (this.productTotal*100/115)* 0.15;
      // this.tax += (this.productTotal/100)* 15;
      // this.productTotalBeforeVat +=(element.totalPrice * element.quantity) ;
    });
    // this.productTotalBeforeVat -= this.tax ;
    // this.productTotal += this.tax;
    this.productTotalBeforeVat += this.productTotal / 1.15;
     this.tax += this.productTotal - this.productTotalBeforeVat;
     if(this.products && this.products.length > 0){

    this.katoraService.updateCartInAPIs()
    .subscribe(result => {
      if(result == true){
        this.total = this.productTotal  - this.couponCode;
        this.subtotalAfterDiscound = this.productTotalBeforeVat  - this.couponCode;
        this.cityService.getAll(0)
        .subscribe(data => {
          this.cities = data;
          this.shipping = new ShippingAdderssModel();
          this.shippingForm = this.CreateShippingAddressForm();
        });
        this.userService.GetUserAccountAmount()
        .subscribe(amount => {
          this.userAccountAmount = amount;
          if(amount == null){
            this.userAccountAmount = 0;
          }
        });
      }
      else{
        window.location.reload();

      }
    })
  }
  if(this.boxes && this.boxes.length > 0){

    this.katoraService.updateBoxCartInAPIs()
    .subscribe(result => {
      if(result == true){
        this.total = this.productTotal  - this.couponCode;
        this.subtotalAfterDiscound = this.productTotalBeforeVat  - this.couponCode;
      }
      else{
        window.location.reload();

      }
    })
  }
    // this.productAddedToCart = this.katoraService.getProductFromCart();
  }
  onPay() {
    this.shipping = this.shippingForm.getRawValue();
    this.billing = this.billingForm.getRawValue();
    // this.payment = this.paymentForm.getRawValue();
    this.checkOut.id                        = 0                       ;
    this.checkOut.orderID                   = this.checkOut.orderID ? this.checkOut.orderID : '';
    this.checkOut.totalPrice                = + this.total                ;
    this.checkOut.status                    = 0                    ;
    this.checkOut.userName                  = this.checkOut.userName                  ;
    this.checkOut.userId                    = this.checkOut.userId                    ;
    this.checkOut.description               = this.checkOut.description ? this.checkOut.description : ''              ;
    this.checkOut.shippingAddressFirstName  = this.shipping.shippingAddressFirstName   ? this.shipping.shippingAddressFirstName   : '';
    this.checkOut.shippingAddressLastName   = this.shipping.shippingAddressLastName    ? this.shipping.shippingAddressLastName    : '';
    this.checkOut.shippingAddressCompanyName = this.shipping.shippingAddressCompanyName ? this.shipping.shippingAddressCompanyName : '';
    this.checkOut.shippingAddressAddress    = this.shipping.shippingAddressAddress     ? this.shipping.shippingAddressAddress     : '';
    this.checkOut.shippingAddressCityId     = +this.shipping.shippingAddressCityId    ? this.shipping.shippingAddressCityId    : null;
    this.checkOut.shippingAddressRegionId   = +this.shipping.shippingAddressRegionId  ? this.shipping.shippingAddressRegionId    : null;
    this.checkOut.shippingAddressCityName   = this.shipping.shippingAddressCityName    ? this.shipping.shippingAddressCityName    : '';
    this.checkOut.shippingAddressRegionName = this.shipping.shippingAddressRegionName  ? this.shipping.shippingAddressRegionName  : '';
    this.checkOut.shippingAddressCity       = this.shipping.shippingAddressCity        ? this.shipping.shippingAddressCity        : '';
    this.checkOut.shippingAddressPhone      = this.shipping.shippingAddressPhone       ? this.shipping.shippingAddressPhone       : '';
    this.checkOut.billingAddressFirstName   = this.billing.billingAddressFirstName    ? this.billing.billingAddressFirstName    : '';
    this.checkOut.billingAddressLastName    = this.billing.billingAddressLastName     ? this.billing.billingAddressLastName     : '';
    this.checkOut.billingAddressCompanyName = this.billing.billingAddressCompanyName  ? this.billing.billingAddressCompanyName  : '';
    this.checkOut.billingAddressAddress     = this.billing.billingAddressAddress      ? this.billing.billingAddressAddress      : '';
    this.checkOut.billingAddressCityId      = + this.billing.billingAddressCityId   ? this.billing.billingAddressCityId   : null;
    this.checkOut.billingAddressRegionId    = + this.billing.billingAddressRegionId ? this.billing.billingAddressRegionId : null;
    this.checkOut.billingAddressCityName    =   this.billing.billingAddressCityName ? this.billing.billingAddressCityName : '' ;
    this.checkOut.billingAddressRegionName  = this.billing.billingAddressRegionName ? this.billing.billingAddressRegionName : '';
    this.checkOut.billingAddressCity        = this.billing.billingAddressCity ? this.billing.billingAddressCity : '';
    this.checkOut.billingAddressPhone       = this.billing.billingAddressPhone ? this.billing.billingAddressPhone  : '';
    this.checkOut.cardName                  = this.payment.cardName            ? this.payment.cardName             : '';
    this.checkOut.cardNumber                = this.payment.cardNumber          ? this.payment.cardNumber           : '';
    this.checkOut.cardType                  = this.payment.cardType            ? this.payment.cardType             : '';
    this.checkOut.expirationMonth           = this.payment.expirationMonth     ? this.payment.expirationMonth      : '';
    this.checkOut.expirationYear            = this.payment.expirationYear      ? this.payment.expirationYear       : '';
    this.checkOut.securityCode              = this.payment.securityCode        ? this.payment.securityCode         : '';
    if(this.usedBalance == true){

      this.checkOut.balanceUsed  = this.userAccountAmount  ;
    }
    else{
      this.checkOut.balanceUsed = 0;
    }
    this.checkOut.tax = this.tax;
    this.checkOut.deleveryPrice = this.deleveryPrice;
    this.checkOut.copenCode = this.couponeCodeValue;
    this.checkOut.branchId                  = 0                 ;
    this.checkOut.createDate                = new Date(new Date().toString() + ' UTC');
    this.checkOut.userId = this.checkOut.userId ? this.checkOut.userId : 0;
    this.checkOut.userName = this.checkOut.userName ? this.checkOut.userName : '';
    this.checkOut.shippingCompanyId = this.checkOut.shippingCompanyId ? this.checkOut.shippingCompanyId:0;
    this.checkOut.paymentType = this.PaymentMethodId;
    var i = this.paymentMethods.findIndex(x=>x.PaymentMethodId == this.PaymentMethodId);
    if(i > -1){
      this.checkOut.paymentImageUrl = this.paymentMethods[i].ImageUrl;
    }
    // if(this.checkOut.cardType == 'VISA'){
    //   this.checkOut.paymentType = 1;
    // }
    // if(this.checkOut.cardType == 'MASTER'){
    //   this.checkOut.paymentType = 2;
    // }
    // if(this.checkOut.cardType == 'MADA'){
    //   this.checkOut.paymentType = 3;
    // }
    this.orderService.createOrder(this.checkOut)
    .subscribe(data => {
     if(data > 0){
      this.OrderId = data;
      this.ExecutePayment();
      this.PurchaseCouponeCodeUsed.forEach(element => {
        this.couponeCodeService.used(element)
        .subscribe(result => {
          if (result === true) {
          }
        });
      });
      if(this.usedBalance == true){
        this.userService.UseUserAccountAmount(this.newAmount)
        .subscribe(result => {
          if(result == true){
          }
          else{
            this.toaster.error("Error Please Try Again");
          }
        });
      }
      // var totalString = this.total.toFixed(2);;
      // location.replace(`${this.baseUrl}/pay/index?amount=${totalString}&&type=${this.payment.cardType}&&givenName=${this.shipping.shippingAddressFirstName}&&orderId=${data}&&email=${this.currentUser.Email}`);
     }
    });
  }
  OrderId = 0;
  // onPay() {
  //   this.shipping = this.shippingForm.getRawValue();
  //   this.billing = this.billingForm.getRawValue();
  //   this.payment = this.paymentForm.getRawValue();
  //   this.checkOut.id                        = 0                       ;
  //   this.checkOut.orderID                   = this.checkOut.orderID ? this.checkOut.orderID : '';
  //   this.checkOut.totalPrice                = + this.total                ;
  //   this.checkOut.status                    = 0                    ;
  //   this.checkOut.userName                  = this.checkOut.userName                  ;
  //   this.checkOut.userId                    = this.checkOut.userId                    ;
  //   this.checkOut.description               = this.checkOut.description ? this.checkOut.description : ''              ;
  //   this.checkOut.shippingAddressFirstName  = this.shipping.shippingAddressFirstName   ? this.shipping.shippingAddressFirstName   : '';
  //   this.checkOut.shippingAddressLastName   = this.shipping.shippingAddressLastName    ? this.shipping.shippingAddressLastName    : '';
  //   this.checkOut.shippingAddressCompanyName = this.shipping.shippingAddressCompanyName ? this.shipping.shippingAddressCompanyName : '';
  //   this.checkOut.shippingAddressAddress    = this.shipping.shippingAddressAddress     ? this.shipping.shippingAddressAddress     : '';
  //   this.checkOut.shippingAddressCityId     = +this.shipping.shippingAddressCityId    ? this.shipping.shippingAddressCityId    : null;
  //   this.checkOut.shippingAddressRegionId   = +this.shipping.shippingAddressRegionId  ? this.shipping.shippingAddressRegionId    : null;
  //   this.checkOut.shippingAddressCityName   = this.shipping.shippingAddressCityName    ? this.shipping.shippingAddressCityName    : '';
  //   this.checkOut.shippingAddressRegionName = this.shipping.shippingAddressRegionName  ? this.shipping.shippingAddressRegionName  : '';
  //   this.checkOut.shippingAddressCity       = this.shipping.shippingAddressCity        ? this.shipping.shippingAddressCity        : '';
  //   this.checkOut.shippingAddressPhone      = this.shipping.shippingAddressPhone       ? this.shipping.shippingAddressPhone       : '';
  //   this.checkOut.billingAddressFirstName   = this.billing.billingAddressFirstName    ? this.billing.billingAddressFirstName    : '';
  //   this.checkOut.billingAddressLastName    = this.billing.billingAddressLastName     ? this.billing.billingAddressLastName     : '';
  //   this.checkOut.billingAddressCompanyName = this.billing.billingAddressCompanyName  ? this.billing.billingAddressCompanyName  : '';
  //   this.checkOut.billingAddressAddress     = this.billing.billingAddressAddress      ? this.billing.billingAddressAddress      : '';
  //   this.checkOut.billingAddressCityId      = + this.billing.billingAddressCityId   ? this.billing.billingAddressCityId   : null;
  //   this.checkOut.billingAddressRegionId    = + this.billing.billingAddressRegionId ? this.billing.billingAddressRegionId : null;
  //   this.checkOut.billingAddressCityName    =   this.billing.billingAddressCityName ? this.billing.billingAddressCityName : '' ;
  //   this.checkOut.billingAddressRegionName  = this.billing.billingAddressRegionName ? this.billing.billingAddressRegionName : '';
  //   this.checkOut.billingAddressCity        = this.billing.billingAddressCity ? this.billing.billingAddressCity : '';
  //   this.checkOut.billingAddressPhone       = this.billing.billingAddressPhone ? this.billing.billingAddressPhone  : '';
  //   this.checkOut.cardName                  = this.payment.cardName            ? this.payment.cardName             : '';
  //   this.checkOut.cardNumber                = this.payment.cardNumber          ? this.payment.cardNumber           : '';
  //   this.checkOut.cardType                  = this.payment.cardType            ? this.payment.cardType             : '';
  //   this.checkOut.expirationMonth           = this.payment.expirationMonth     ? this.payment.expirationMonth      : '';
  //   this.checkOut.expirationYear            = this.payment.expirationYear      ? this.payment.expirationYear       : '';
  //   this.checkOut.securityCode              = this.payment.securityCode        ? this.payment.securityCode         : '';
  //   this.products.forEach(element => {
  //     element.productPrice = element.totalPrice;
  //   });
  //   this.checkOut.carts = this.products;
  //   if(this.usedBalance == true){

  //     this.checkOut.balanceUsed  = this.userAccountAmount  ;
  //   }
  //   else{
  //     this.checkOut.balanceUsed = 0;
  //   }
  //   this.checkOut.tax = this.tax;
  //   this.checkOut.deleveryPrice = this.deleveryPrice;
  //   this.checkOut.copenCode = this.couponeCodeValue;
  //   this.checkOut.branchId                  = 0                 ;
  //   this.checkOut.createDate                = new Date(new Date().toString() + ' UTC');
  //   this.checkOut.userId = this.checkOut.userId ? this.checkOut.userId : 0;
  //   this.checkOut.userName = this.checkOut.userName ? this.checkOut.userName : '';
  //   this.checkOut.shippingCompanyId = this.checkOut.shippingCompanyId ? this.checkOut.shippingCompanyId:0;
  //   if(this.checkOut.cardType == 'VISA'){
  //     this.checkOut.paymentType = 1;
  //   }
  //   if(this.checkOut.cardType == 'MASTER'){
  //     this.checkOut.paymentType = 2;
  //   }
  //   if(this.checkOut.cardType == 'MADA'){
  //     this.checkOut.paymentType = 3;
  //   }
  //   this.orderService.createOrder(this.checkOut)
  //   .subscribe(data => {
  //    if(data > 0){
  //     this.OrderId = data;
  //     this.ExecutePayment();
  //     this.PurchaseCouponeCodeUsed.forEach(element => {
  //       this.couponeCodeService.used(element)
  //       .subscribe(result => {
  //         if (result === true) {
  //         }
  //       });
  //     });
  //     if(this.usedBalance == true){
  //       this.userService.UseUserAccountAmount(this.newAmount)
  //       .subscribe(result => {
  //         if(result == true){
  //         }
  //         else{
  //           this.toaster.error("Error Please Try Again");
  //         }
  //       });
  //     }
  //     var totalString = this.total.toFixed(2);;
  //     this.currentUser.Email = this.shippingForm.controls['email'].value;
  //     // location.replace(`${this.baseUrl}/pay/index?amount=${totalString}&&type=${this.payment.cardType}&&givenName=${this.shipping.shippingAddressFirstName}&&orderId=${data}&&email=${this.currentUser.Email}`);
  //    }
    
  //     // if(data === true) {
  //     //   this.productAddedToCart.forEach(element => {
  //     //   this.katoraService.removeProductFromCart(element);
  //     //   });
  //     //   this.router.navigate(['/']);
  //     // }
  //   });
  // }
 paymentMethods : PaymentMethodsModel[]=[];
 initializePayment(){
  this.myFatoorahServeice.InitiatePayment(+ this.total)
  .subscribe(initiateResult => {
    this.step = 'pills-method';
    this.stepId = 'payment'
    this.paymentMethods = initiateResult.Data.PaymentMethods;

  })
 }
 chickAvalableProgress = false;

  chooseShippingResions() {
    this.shipping = this.shippingForm.getRawValue();
    const i = this.cities.findIndex(x => x.id == this.shipping.shippingAddressCityId);
    if (i !== -1) {
      this.total -= this.deleveryPrice;
      this.checkOut.shippingAddressCityId = this.shipping.shippingAddressCityId;
      this.deleveryPrice = +this.cities[i].deliveryPrice;
      this.shippingCity = this.cities[i];
      this.deleveryPriceVat = (this.deleveryPrice/100)* 15 ;
      this.total = this.productTotal + ( this.deleveryPrice + this.deleveryPriceVat);
    }
    this.cityService.GetResionsByCityId(this.shipping.shippingAddressCityId)
    .subscribe(data => {
      this.sippingResions = data;
      var model = new RegionModel({id:-1,deliveryPrice:0,isDeleted:false,nameAr:"الحي غير معروف",nameEn:'UnKnown District',nameFr:'UnKnown District'})
      model.id = -1;
      this.sippingResions.splice(0,0,model)
      this.setDeleveryPrice();
    });
     
    this.checkQuantity = new CheckQuantityModel();
    this.checkQuantity.collection=this.products;
    this.checkQuantity.cityId = + this.shippingForm.controls['shippingAddressCityId'].value;
    this.chickAvalableProgress = true;
    this.cartService.checkQuantity(this.checkQuantity)
    .subscribe(data =>{
      this.products = data;
      this.products.forEach(element => {
        if(element.avalable != true){
          this.prodectNotAvalable = true;
          return;
        }
      });
      this.chickAvalableProgress = false;

    })
  }
  prodectNotAvalable = false;
  setDeleveryPrice() {
    this.shipping = this.shippingForm.getRawValue();
    if(this.shipping.shippingAddressRegionId > 0){
    const i = this.sippingResions.findIndex(x => x.id == this.shipping.shippingAddressRegionId);
    // this.setUnKnownDistrict(false);
    if (i !== -1) {
      this.total -= this.deleveryPrice;
      this.total -= this.deleveryPriceVat;
      this.deleveryPrice = +this.sippingResions[i].deliveryPrice;
      this.deleveryPriceVat =(this.deleveryPrice/100)* 15 ;
      this.total = this.productTotal +  (this.deleveryPrice + this.deleveryPriceVat);
    }
    else{
      this.total -= this.deleveryPrice;
      this.total -= this.deleveryPriceVat;
      this.deleveryPrice = +this.shippingCity.deliveryPrice;
      this.deleveryPriceVat = (this.deleveryPrice/100)* 15 ;
      this.total = this.productTotal +  (this.deleveryPrice + this.deleveryPriceVat);
    }
    if(this.shippingCity.deleveryList.length>0){
      var LastBig = 0;
      this.shippingCity.deleveryList.forEach(element => {
        if(this.productTotal > element.minOrder && element.minOrder > LastBig){
          this.deleveryPrice = element.deleveryAmount;
          this.total -= this.deleveryPrice;
          this.total -= this.deleveryPriceVat;
          this.deleveryPriceVat = (this.deleveryPrice/100)* 15 ;
          this.total = this.productTotal +  (this.deleveryPrice + this.deleveryPriceVat);
          LastBig = element.minOrder;
        }
      });
    }
  }
    else if(this.shipping.shippingAddressRegionId == -1) {
      this.fastShipping = false;
      this.setUnKnownDistrict(true);
    }
  }
  setFastShipping(e){
    if(this.fastShipping == true){
      this.shippingForm.controls['unknownDistrict'].setValue(false);
      this.total -= this.deleveryPrice;
      this.total -= this.deleveryPriceVat;
      this.deleveryPrice = +this.shippingCity.fastShippingPrice;
      this.deleveryPriceVat = (this.deleveryPrice/100)* 15 ;
      this.total = this.productTotal + (this.deleveryPrice + this.deleveryPriceVat);
    }
    else{
      this.setDeleveryPrice();
    }
  }
  chooseBillingResions() {
    this.billing = this.billingForm.getRawValue();
    this.cityService.GetResionsByCityId(this.billing.billingAddressCityId)
    .subscribe(data => {
      this.billingResions = data;
    });
  }
  addQuantity(product: any) {
    this.katoraService.onAddQuantity(product);
  }
  removeQuantity(product: any) {
    this.katoraService.onRemoveQuantity(product);
  }
  removeProduct(product: any) {
    this.katoraService.removeProductFromCart(product);
  }
  addShippingData() {
    this.step  = 'pills-billing';
    this.shipping = this.shippingForm.getRawValue();
    this.newAddress = new ShoppingAddressModel();
    this.newAddress.address = this.shipping.shippingAddressAddress;
    this.newAddress.cityId = this.shipping.shippingAddressCityId;
    this.newAddress.companyName = this.shipping.shippingAddressCompanyName;
    this.newAddress.firstName = this.shipping.shippingAddressFirstName;
    this.newAddress.isMain = false;
    this.newAddress.lastName = this.shipping.shippingAddressLastName;
    this.newAddress.phone = this.shipping.shippingAddressPhone;
    this.newAddress.resionId = this.shipping.shippingAddressRegionId;
    this.shippingAddressService.createShoppingAddress(this.newAddress)
    .subscribe(data=>{
    })
    const formData = {
      shippingFirstName : this.checkOut.shippingAddressFirstName,
      shippingLastName : this.checkOut.shippingAddressLastName,
      shippingCompanyName : this.checkOut.shippingAddressCompanyName,
      shippingAddress : this.checkOut.shippingAddressAddress,
      shippingCountry : this.checkOut.shippingAddressCityId,
      shippingState : this.checkOut.shippingAddressRegionId,
      shippingCity : this.checkOut.shippingAddressCity,
      shippingPhone : this.checkOut.shippingAddressPhone,
    }
    localStorage.setItem('orderData', JSON.stringify(formData));
  }
  addBillingData() {
    this.step  = 'pills-order';
    this.billing = this.billingForm.getRawValue();
    this.payment = new PaymentModel();
    this.paymentForm = this.CreatePaymentAddressForm();
    const formData = {
      shippingFirstName : this.checkOut.shippingAddressFirstName,
      shippingLastName : this.checkOut.shippingAddressLastName,
      shippingCompanyName : this.checkOut.shippingAddressCompanyName,
      shippingAddress : this.checkOut.shippingAddressAddress,
      shippingCountry : this.checkOut.shippingAddressCityId,
      shippingState : this.checkOut.shippingAddressRegionId,
      shippingCity : this.checkOut.shippingAddressCity,

      shippingPhone : this.checkOut.shippingAddressPhone,

      billingFirstName : this.checkOut.shippingAddressFirstName,
      billingLastName : this.checkOut.shippingAddressLastName,
      billingCompanyName : this.checkOut.shippingAddressCompanyName,
      billingAddress : this.checkOut.shippingAddressAddress,
      billingCountry : this.checkOut.shippingAddressCityId,
      billingState : this.checkOut.shippingAddressRegionId,
      billingCity : this.checkOut.shippingAddressCity,

      billingPhone : this.checkOut.shippingAddressPhone,

    }
    localStorage.setItem('orderData', JSON.stringify(formData));
  }
  setStep(item:string){
    this.step = item;
  }
  PurchaseCouponeCodeUsed : PurchaseCouponModel[]=[];
  calcBill() {
    var found= this.PurchaseCouponeCodeUsed.findIndex(x=>x.code == this.PurchaseCouponeCode)
    if(found == -1){
      this.couponeCodeService.GetByCode(this.PurchaseCouponeCode)
      .subscribe(data => {
        if (data.id > 0) {

          this.PurchaseCouponeCodeUsed.push(data);
          this.total -= data.amount;
          this.couponeCodeService.used(data)
          .subscribe(result => {
            if (result === true) {
              if (data.amount < this.total) {
                this.total -= data.amount;
                this.productTotal -= data.amount;

              }
              else{
                this.total = 0;
                this.total = 0;
              }
            }
            else {
              this.toaster.error('Error During Used This Code');
            }
          });
        }
        else {
          this.toaster.error('Plase Enter Valid Code');
        }
      });
    }
    else {
      this.toaster.error('Plase Enter Valid Code');
    }
    
  }
  couponeCodeValue = 0;
  disablecouponeCode= false;
  calcCoupon() {
    this.disablecouponeCode = true;

    var found= this.PurchaseCouponeCodeUsed.findIndex(x=>x.code == this.CouponeCode)
    if(found == -1){
      this.couponeCodeService.GetByCode(this.CouponeCode)
      .subscribe(data => {
         
        if (data.id > 0) {
          if (data.id > 0) {
            var x = data.amount / 100;
            var value = this.productTotalBeforeVat * x;
            if (this.subtotalAfterDiscound > value) {
              this.total -= value;
               this.subtotalAfterDiscound = this.productTotalBeforeVat - value;
               this.couponeCodeValue += value;
               this.disablecouponeCode = true;
               this.productTotal = this.subtotalAfterDiscound * 1.15;
              var vat= this.productTotal / 1.15;
              this.tax = this.productTotal - vat;
              this.total = this.productTotal + (this.deleveryPrice + this.deleveryPriceVat); ;
            }
            else{
              this.disablecouponeCode = true;
              this.total = 0;
              this.subtotalAfterDiscound = 0;
              this.productTotal = 0;
              this.subtotalAfterDiscound += this.productTotal / 1.15;
              this.tax += this.productTotal - this.subtotalAfterDiscound;
              this.couponeCodeValue += value;
            }
          }
        }
        else {
          this.toaster.error('Plase Enter Valid Code');
          this.disablecouponeCode = false;

        }
      });
    }
    else {
      this.toaster.error('This Code Is Used Please Enter Onother Code');
      this.disablecouponeCode = false;
    }
 
  }
  sameShippingAndBilling($event){
    if ($event.currentTarget.checked === true) {
    this.shipping = this.shippingForm.getRawValue();
      this.cityService.GetResionsByCityId(this.shipping.shippingAddressCityId)
      .subscribe(data => {
        this.billingResions = data;
        this.billing.billingAddressAddress = this.shipping.shippingAddressAddress;
        this.billing.billingAddressCity = this.shipping.shippingAddressCity;
        this.billing.billingAddressCityId = +this.shipping.shippingAddressCityId;
        this.billing.billingAddressCompanyName = this.shipping.shippingAddressCompanyName;
        this.billing.billingAddressFirstName = this.shipping.shippingAddressFirstName;
        this.billing.billingAddressLastName = this.shipping.shippingAddressLastName;
        this.billing.billingAddressPhone = this.shipping.shippingAddressPhone;
        this.billing.billingAddressRegionId = +this.shipping.shippingAddressRegionId;
        this.billingForm = this.CreateBillingAddressForm();
      });
    }
    else {
      this.billingResions = [] ;
      this.billing.billingAddressAddress = '';
      this.billing.billingAddressCity = '';
      this.billing.billingAddressCityId = 0;
      this.billing.billingAddressCompanyName = '';
      this.billing.billingAddressFirstName = '';
      this.billing.billingAddressLastName = '';
      this.billing.billingAddressPhone = '';
      this.billing.billingAddressRegionId = 0;
      this.billingForm = this.CreateBillingAddressForm();
    }
  }
  newAmount = 0;
  usedBalance = false;
  useBalance(){
    this.newAmount = this.userAccountAmount - this.total;
    if(this.newAmount < 0){
      this.newAmount = 0;
      
    }
    this.total -= this.userAccountAmount;
    this.userAccountAmount -= this.userAccountAmount;
    this.usedBalance = true;
   
  }
  openShippingAddressForm = false;
  CreateShippingAddressForm():FormGroup{
    this.openShippingAddressForm = true;
    return this.fb.group({
      shippingAddressFirstName  : [this.shipping.shippingAddressFirstName,Validators.required],
      shippingAddressLastName   : [this.shipping.shippingAddressLastName,Validators.required],
      shippingAddressCompanyName:[this.shipping.shippingAddressCompanyName],
      shippingAddressAddress    :[this.shipping.shippingAddressAddress],
      shippingAddressCityId     :[this.shipping.shippingAddressCityId,Validators.pattern('^[1-9][0-9]*$')],
      shippingAddressRegionId   :[this.shipping.shippingAddressRegionId,Validators.required],
      shippingAddressPhone : [this.shipping.shippingAddressPhone,Validators.required],
      fastShipping : [false],
      unknownDistrict : [false],
    })
  }
  openBillingAddressForm = false;
  CreateBillingAddressForm():FormGroup{
    this.openBillingAddressForm = true;
    return this.fb.group({
      billingAddressFirstName  : [this.billing.billingAddressFirstName,Validators.required],
      billingAddressLastName   : [this.billing.billingAddressLastName,Validators.required],
      billingAddressCompanyName:[this.billing.billingAddressCompanyName],
      billingAddressAddress    :[this.billing.billingAddressAddress,Validators.required],
      billingAddressCityId     :[this.billing.billingAddressCityId,Validators.pattern('^[1-9][0-9]*$')],
      billingAddressRegionId   :[this.billing.billingAddressRegionId,Validators.required],
      billingAddressPhone : [this.billing.billingAddressPhone,Validators.required]
    })
  }
  openPaymentAddressForm = false;
  CreatePaymentAddressForm():FormGroup{
    this.openPaymentAddressForm = true;
    return this.fb.group({
      cardName  : [this.payment.cardName],
      cardNumber   : [this.payment.cardNumber],
      cardType:[this.payment.cardType,Validators.required],
      expirationMonth    :[this.payment.expirationMonth],
      expirationYear     :[this.payment.expirationYear],
      securityCode   :[this.payment.securityCode],
    })
  }
  UnKnownDistrict = false;
  setUnKnownDistrict(value:boolean){
      this.UnKnownDistrict = value;
      if(value == true){
        this.shippingForm.controls['fastShipping'].setValue(false);
        this.shippingForm.controls['shippingAddressRegionId'].setValue(-1);
      this.shippingForm.controls['shippingAddressRegionId'].clearValidators();
      this.total -= this.deleveryPrice;
      this.total -= this.deleveryPriceVat;
      this.deleveryPrice = +this.shippingCity.unKnownDistrictPrice;
      this.deleveryPriceVat = (this.deleveryPrice/100)* 15 ;
      this.total = this.productTotal + (this.deleveryPrice + this.deleveryPriceVat);
   
   }
   else{
     this.setDeleveryPrice();
   }
     }
     PaymentMethodId = 0;
     myFatoorahExecutePayment : ExecutePaymentRequestModel={};
     ExecutePayment(){
      this.shipping = this.shippingForm.getRawValue();
      this.myFatoorahExecutePayment = new ExecutePaymentRequestModel();
      this.myFatoorahExecutePayment.PaymentMethodId = this.PaymentMethodId;
      this.myFatoorahExecutePayment.CallBackUrl = environment.CallBackUrl+ this.OrderId.toString();
      this.myFatoorahExecutePayment.ErrorUrl = environment.ErrorUrl;
      this.myFatoorahExecutePayment.CustomerEmail = this.currentUser.Email// this.shippingForm.controls['email'].value;
      this.myFatoorahExecutePayment.CustomerMobile = this.shipping.shippingAddressPhone;
      this.myFatoorahExecutePayment.CustomerName = this.shipping.shippingAddressFirstName + ' ' + this.shipping.shippingAddressLastName;
      this.myFatoorahExecutePayment.InvoiceValue = +this.total;
      if(this.current_language == 'ar')
      {
        this.myFatoorahExecutePayment.Language = 'AR';
      }
      else{
        this.myFatoorahExecutePayment.Language = 'EN';
      }
      this.myFatoorahServeice.MyFatoorahPay(this.myFatoorahExecutePayment)
      .subscribe(data => {
        if(data.IsSuccess){
          location.replace(data.Data.PaymentURL);
        }
      })
     }

     ariaExpanded = false;
     stepId='shipping';
     sameBillingAndShippingAddress = true;
     CreateAccount = false;
     goToPayment(){
      this.addShippingData();
      this.shipping = this.shippingForm.getRawValue();
      this.cityService.GetResionsByCityId(this.shipping.shippingAddressCityId)
      .subscribe(data => {
        this.billingResions = data;
        this.billing.billingAddressAddress = this.shipping.shippingAddressAddress;
        this.billing.billingAddressCity = this.shipping.shippingAddressCity;
        this.billing.billingAddressCityId = +this.shipping.shippingAddressCityId;
        this.billing.billingAddressCompanyName = this.shipping.shippingAddressCompanyName;
        this.billing.billingAddressFirstName = this.shipping.shippingAddressFirstName;
        this.billing.billingAddressLastName = this.shipping.shippingAddressLastName;
        this.billing.billingAddressPhone = this.shipping.shippingAddressPhone;
        this.billing.billingAddressRegionId = +this.shipping.shippingAddressRegionId;
        this.billingForm = this.CreateBillingAddressForm();
        this.initializePayment();
        this.addShippingData()
      });
     }
     notSameShippingAndBilling($event){
      this.sameShippingAndBilling($event)
      this.sameBillingAndShippingAddress = $event.currentTarget.checked;
     }
     acceptedTermsandCondition = false;
}
