import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { CheckQuantityModel } from '../../checkout/models/check-quantity.model';
import { CartModel } from '../models/Cart.model';
@Injectable({
  providedIn: 'root'
})
export class CartService {
  public cartProducts = [];
  public wishlistProducts = [];
  public count;
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient, private toastr: ToastrService ) {
 
  //   const token = localStorage.getItem('token');
  //   if (token != null) {
  //   this.GetCount()
  //   .subscribe(data => {
  //     localStorage.setItem('cartCount' , data.toString());
  //   });
  //   localStorage.removeItem('cartProducts');
  //   this.GetForCurrentUser()
  //   .subscribe(data => {
  //     data.forEach(element => {
  //       this.addProductToCart(element);
  //     });
  //   });
  // }
  }
  getProductFromCart() {
    if (localStorage.getItem('cartProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('cartProducts'));
  }
  addProductToCart(products: any) {
    let count = 0;
    this.cartProducts.forEach(element => {


      if (products.id == element.id) {
        count++;
      }


    });
    if (count == 0) {
      if (products.quantity <= 0) {
        products.quantity = 1;
      }
      this.cartProducts.push(products);
      this.updateCartProduct();
    }
  }
  removeProductFromCart(products: any) {
 
    let count = 0;
    this.cartProducts.forEach((element) => {
      if (products.id == element.id) {
        const i =this.cartProducts.findIndex(x => x.id == products.id);
        this.cartProducts.splice(i, 1);
        count++;
      }
    });
    if (count != 0) {
      this.updateCartProduct();
    }
  }
  updateCartProduct() {
    localStorage.setItem('cartProducts', JSON.stringify(this.cartProducts));
  }
  GetForCurrentUser(): Observable<CartModel[]> {
    return this.http
      .get<CartModel[]>(`${this.baseUrl}/Cart/GetForCurrentUser`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByOrderId(orderId: number): Observable<CartModel[]> {
    return this.http
      .get<CartModel[]>(`${this.baseUrl}/Cart/GetByOrderId/${orderId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/Cart/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createCart(payload: CartModel): Observable<number> {
     
        return this.http
          .post<number>(`${this.baseUrl}/Cart/Insert`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
      
InsertList(payload: CartModel[]): Observable<boolean> {
   return this.http
     .post<boolean>(`${this.baseUrl}/Cart/InsertList`, payload, this.httpOptions)
     .pipe(catchError((error: any) => Observable.throw(error.json())));
 }
 InsertListAsAGuest(payload: CartModel[]): Observable<boolean> {
  return this.http
    .post<boolean>(`${this.baseUrl}/Cart/InsertListAsAGuest`, payload, this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
}
 
 put(payload: CartModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/Cart/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 checkQuantity(payload:CheckQuantityModel):Observable<CartModel[]>{
    
   return this.http
   .put<CartModel[]>(`${this.baseUrl}/Cart/CheckQuantity`, payload, this.httpOptions)
   .pipe(catchError((error: any) => Observable.throw(error.json())));
 }
 delete(payload: CartModel): Observable<boolean> {
        return this.http
          .get<boolean>(`${this.baseUrl}/Cart/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
