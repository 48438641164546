import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-langauge-dropdown',
  templateUrl: './langauge-dropdown.component.html',
  styleUrls: ['./langauge-dropdown.component.scss']
})
export class LangaugeDropdownComponent implements OnInit {

  public current_language;


  constructor(
    public translate: TranslateService,
     private renderer: Renderer2,
     public activeRouter:ActivatedRoute,
     public router:Router) {

    this.current_language = localStorage.getItem('lang');

    if (this.current_language == 'ar') {

      this.renderer.addClass(document.body, 'bodyrtl');
    }
    else {
      this.renderer.removeClass(document.body, 'bodyrtl');
    }
  }





  public setLanguage = (language) => {
    if (this.current_language === language) return;

 
    if (this.current_language == 'ar') {
      if(this.activeRouter.snapshot.url.length > 0){
        if(this.activeRouter.snapshot.url[0].path=="giving-gift"){
          this.router.navigate(['giving-gift-en',this.activeRouter.snapshot.url[1].path])
        }
        else{

          window.location.reload();
        } 
      }
   
      else{

        window.location.reload();
      }
      this.renderer.addClass(document.body, 'bodyrtl');
    }
    else {
      if(this.activeRouter.snapshot.url.length > 0){

      if(this.activeRouter.snapshot.url[0].path=="giving-gift-en"){
        this.router.navigate(['giving-gift',this.activeRouter.snapshot.url[1].path])
      }
      else{

        window.location.reload();
      } 
    }
      else{

        window.location.reload();
      }      
      this.renderer.removeClass(document.body, 'bodyrtl');
    }
    this.current_language = language;

    localStorage.setItem('lang', this.current_language);
    this.translate.use(this.current_language);

  }


  ngOnInit() {
  }

}
