import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { KatoraService } from 'src/app/services/katora.service';
import { PageModel } from '../../global-models/page.model';
import { CartModel } from '../models/Cart.model';
import { CartService } from '../services/cart.service';
import { WishListService } from '../services/wish-list.service';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  public productAddedToWishlist = [];
  public productTotal;
  page: PageModel = {};
  cartModel: CartModel = {} ;
  wishlistCount = 0;
  public current_language;

  constructor(
    private katoraService : KatoraService,
    private wishListService: WishListService,
    private cartService: CartService,
    public toaster: ToastrService,
    public translate: TranslateService,
    ){ 
    this.current_language = localStorage.getItem('lang');
     }
  ngOnInit() { 
    // this.wishListService.GetCount()
    // .subscribe(count => {
    //   this.page.count = count;
    // })
    this.wishlistCount = this.katoraService.getWishlistCount();
    this.page.count = this.wishlistCount;
    this.productAddedToWishlist = this.katoraService.getProductFromWishlist();
    this.productAddedToWishlist.forEach(element => {
      if(element.quantity <= 0 || element.quantity == undefined) {
              element.quantity = 1;
            }
    });
    // this.wishListService.GetForCurrentUser(0)
    // .subscribe(data => {
    //   this.productAddedToWishlist = data;
    //   this.productAddedToWishlist.forEach(element => {
    //     if(element.quantity <= 0) {
    //       element.quantity = 1;
    //     }
    //   });
    // });
  }
  // ngAfterContentChecked(){
  //   this.productAddedToWishlist = this.katoraService.getProductFromWishlist();  
  //   this.productTotal =  this.katoraService.calculateTotal().toFixed(2);  
  // }
  onAddCart(product: any) {
    this.cartModel.id = product.productId;
    this.cartModel.imageUrl = product.imageUrl;
    this.cartModel.productId = product.productId;
    this.cartModel.quantity = product.amount;
    this.cartModel.totalPrice = product.price;
    this.cartModel.quantity = 1;
    this.cartModel.productUnits = product.productUnits;
    this.cartModel.productNameAr = product.productNameAr;
    this.cartModel.productNameEn = product.productNameEn;
    this.katoraService.addProductToCart(this.cartModel);
    // this.cartModel.productPrice = product.productPrice;
    // this.cartService.createCart(this.cartModel)
    // .subscribe(result => {
    //   if (result > 0) {
    //     this.toaster.success('Product Added To Cart');
    //     let count = + localStorage.getItem('cartCount');
    //     count ++;
    //     localStorage.removeItem('cartCount');
    //     localStorage.setItem('cartCount', count.toString());
    //     this.cartModel.id = result;
    //     let products = JSON.parse(localStorage.getItem('cartProducts'));
    //     const i = products.findIndex(x => x.id === this.cartModel.id);
    //     if (i === -1) {
    //       product.totalPrice = product.price;
    //       product.quantity = 1;
    //       products.push(product);
    //       localStorage.setItem('cartProducts' , JSON.stringify(products));
    //     }
    //   }
    // });
  }
  addQuantity(product: any) {
    const i = this.productAddedToWishlist.indexOf(product);
    if (i !== -1) {
      this.katoraService.onAddQuantity(this.productAddedToWishlist[i])
      this.productAddedToWishlist[i].quantity ++;
      // this.wishListService.put(this.productAddedToWishlist[i])
      // .subscribe(result => {
      //   let products = JSON.parse(localStorage.getItem('wishlistProducts'));
      //   const i = products.findIndex(x => x.id === product.id);
      //   if (i !== -1) {
      //     products[i].quantity ++;
      //     localStorage.setItem('wishlistProducts' , JSON.stringify(products));
      //   }
      // });
    }
  }
  removeQuantity(product: any) {
    const i = this.productAddedToWishlist.indexOf(product);
    if (i !== -1) {
      this.katoraService.removeQuantity(this.productAddedToWishlist[i]);
      this.productAddedToWishlist[i].quantity --;
      // this.wishListService.put(this.productAddedToWishlist[i])
      // .subscribe(result => {
      //   let products = JSON.parse(localStorage.getItem('wishlistProducts'));
      //   const i = products.findIndex(x => x.id === product.id);
      //   if (i !== -1) {
      //     products[i].quantity --;
      //     localStorage.setItem('wishlistProducts' , JSON.stringify(products));
      //   }
      // });
    }
  }
  removeProduct(product: any) {
     
    this.wishListService.removeProductFromWishlist(product);
    const token = localStorage.getItem('token');
    if (token != null) {
     this.wishListService.delete(product)
    .subscribe(result => {
     
    });
  }
  this.toaster.success('Product Deleted From Withlist');
  this.ngOnInit();
    // let products = JSON.parse(localStorage.getItem('wishlistProducts'));
    // const i = products.findIndex(x => x.id === product.id);
    // if (i !== -1) {
    //   products.splice(i, 1);
    //   localStorage.setItem('wishlistProducts' , JSON.stringify(products));
    // }
    // this.wishListService.delete(product)
    // .subscribe(result => {
    //   if (result === true){
    //     this.toaster.success('Product Deleted From Withlist');
    //   }
    // });
  }
  datatablePageData(pageInfo: { count?: number, pageSize?: number, limit?: number, offset?: number }) {
    this.wishListService.GetForCurrentUser(pageInfo.offset + 1)
    .subscribe(data => {
        this.productAddedToWishlist = data;
    });
}

}
