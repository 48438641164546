import { logging } from 'protractor';
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output, HostListener, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ProductModel } from 'src/app/pages/shop/models/Product.model';
import { CartModel } from 'src/app/pages/user-account/models/Cart.model';
import { WishListModel } from 'src/app/pages/user-account/models/wish-list.model';
import { CartService } from 'src/app/pages/user-account/services/cart.service';
import { WishListService } from 'src/app/pages/user-account/services/wish-list.service';
import { KatoraService } from '../../services/katora.service';
import { ProductModalComponent } from '../product-modal/product-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from 'src/app/pages/user-account/services/user.service';
import { SessionService } from 'src/app/pages/session/session.service';
import { ProductService } from 'src/app/pages/shop/product.service';
import { SlectCityComponent } from '../slect-city/slect-city.component';
import { error } from 'console';
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Output()
  private urlChange = new EventEmitter();
  @Input() data: any;
  Product: ProductModel = {};
  openView = false;
  cartModel: CartModel = {};
  wishList: any = {};
  unitPrice = 0;
  productPrice = 0;
  public current_language;
  public getScreenWidth: any;
  public getScreenHeight: any;
  mouseoverId=0;
  changemouseover(id){
    var div = document.getElementById(this.data.productId+"_"+this.data.listId);
    div.classList.remove("hidden")
  
    this.mouseoverId = this.data.productId;
  }
  changemouseout(id){
    var div = document.getElementById(id);
   
    if(this.getScreenWidth > 1024)
    {
      div.classList.add("hidden")
    }
  }
  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
    if(this.data.price > 0){
      this.productPrice = this.data.price;

    }
    else if(this.data.productUnits && this.data.productUnits.length > 0){
      this.productPrice = this.data.productUnits[0].price;

    }
  }
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
    this.getScreenHeight = window.innerHeight;
  }
  constructor(
    private katoraService: KatoraService,
    private cartService: CartService,
    public toaster: ToastrService,
    private wishListService: WishListService,
    private matDialog : MatDialog,
    public translate: TranslateService,
    private sessionService:SessionService,
    private productService:ProductService,
    private toastr: ToastrService,
    private eRef: ElementRef
    ){
      this.current_language = localStorage.getItem('lang');

     }
 
    onAddWishlist(product: any) {
    this.wishList.id = product.productId;
    this.wishList.productId = product.productId;
    this.wishList.quantity = product.amount;
    this.wishList.imageUrl = product.imageUrl;
    this.wishList.productNameEn = product.productNameEn;
    this.wishList.productNameAr = product.productNameAr;
    this.wishList.totalPrice = product.price;
    this.wishList.descriptionEn = product.smallDescriptionEn;
    this.wishList.descriptionAr = product.smallDescriptionAr;
    this.katoraService.addProductToWishlist(this.wishList);
    // this.wishListService.createWishList(this.wishList)
    // .subscribe(result => {
    //   if (result > 0) {
    //     this.toaster.success('Product Added To wishlist');
    //     let count = + localStorage.getItem('wishlistCount');
    //     count ++;
    //     localStorage.removeItem('wishlistCount');
    //     localStorage.setItem('wishlistCount', count.toString());
    //     this.wishList.id = result;
    //     let products = JSON.parse(localStorage.getItem('wishlistProducts'));
    //     const i = products.findIndex(x => x.id === this.wishList.id);
    //     if (i === -1) {
    //       products.push(product);
    //       localStorage.setItem('wishlistProducts' , JSON.stringify(products));
    //     }
    //   }
    // });
    // this.katoraService.addProductToCart(product);
  }
  openListOfSize = false;
  closePrices(){
  this.openListOfSize = false;
  }
  onAddCart(product: any) {
    if(!product.amount){
      product.amount = 1;
    }
    if(product.productUnits && product.productUnits.length > 0){
      this.openListOfSize = true;
    }
    else{
      if(this.sessionService.isLoggedIn()){
        this.productService.ChickGqantity(product.productId,product.amount,0)
        .subscribe(result=>{
          if(result == true){
            this.cartModel.id = product.productId;
            this.cartModel.imageUrl = product.imageUrl;
            this.cartModel.productId = product.productId;
            this.cartModel.quantity = product.amount;
            this.cartModel.totalPrice = product.price;
            this.cartModel.quantity = 1;
            this.cartModel.productUnits = product.productUnits;
            this.cartModel.productNameAr = product.productNameAr;
            this.cartModel.productNameEn = product.productNameEn;
            this.katoraService.addProductToCart(this.cartModel);
          }
          else if(result == false){
            this.toastr.error("This product is not available in this branch");
  
          }
          else{
            window.location.reload();
          }
        },error=>{
          window.location.reload();
  
        })
      }
      else{
        var cityId= localStorage.getItem('CityId')
        if(cityId == null || cityId == undefined || cityId == ''){
          let dialogRef : MatDialogRef<SlectCityComponent>;
          dialogRef =this.matDialog.open(SlectCityComponent,{ disableClose: true });
          dialogRef.afterClosed()
          .subscribe(x=>{
            cityId = x.cityId;
  
            this.productService.ChickGqantity(product.productId,product.amount,+cityId)
            .subscribe(result=>{
              if(result == true){
                localStorage.setItem('CityId',cityId.toString());
                this.cartModel.id = product.productId;
                this.cartModel.imageUrl = product.imageUrl;
                this.cartModel.productId = product.productId;
                this.cartModel.quantity = product.amount;
                this.cartModel.totalPrice = product.price;
                this.cartModel.productNameAr = product.productNameAr;
                this.cartModel.productNameEn = product.productNameEn;
                this.cartModel.quantity = 1;
                this.cartModel.productUnits = product.productUnits;
                this.katoraService.addProductToCart(this.cartModel);
              }
              else if(result == false){
                this.toastr.error("This product is not available in this branch");
      
              }
              else{
                window.location.reload();
              }
            },error=>{
              window.location.reload();
      
            })   
          })
  
        }
      else{
        this.productService.ChickGqantity(product.productId,product.amount,+cityId)
        .subscribe(result=>{
          if(result == true){
            this.cartModel.id = product.productId;
            this.cartModel.imageUrl = product.imageUrl;
            this.cartModel.productId = product.productId;
            this.cartModel.quantity = product.amount;
            this.cartModel.totalPrice = product.price;
            this.cartModel.quantity = 1;
            this.cartModel.productUnits = product.productUnits;
            this.cartModel.productNameAr = product.productNameAr;
            this.cartModel.productNameEn = product.productNameEn;
            this.katoraService.addProductToCart(this.cartModel);
          }
          else if(result == false){
            this.toastr.error("This product is not available in this branch");
          }
          else{
            window.location.reload();
          }
        },error=>{
          window.location.reload();
  
        }) 
      }
      // this.cartModel.productPrice = product.productPrice;
      // this.cartService.createCart(this.cartModel)
      // .subscribe(result => {
      //   if (result > 0) {
      //     this.toaster.success('Product Added To Cart');
      //     let count = + localStorage.getItem('cartCount');
      //     count ++;
      //     localStorage.removeItem('cartCount');
      //     localStorage.setItem('cartCount', count.toString());
      //     this.cartModel.id = result;
      //     let products = JSON.parse(localStorage.getItem('cartProducts'));
      //     const i = products.findIndex(x => x.id === this.cartModel.id);
      //     if (i === -1) {
      //       product.totalPrice = product.price;
      //       product.quantity = 1;
      //       products.push(product);
      //       localStorage.setItem('cartProducts' , JSON.stringify(products));
      //     }
      //   }
      // });
    }
    }
  
}
  isView = false;
  View(data) {
    this.isView = true;
    let dialogRef : MatDialogRef<ProductModalComponent>;
		dialogRef =this.matDialog.open(ProductModalComponent);
		dialogRef.componentInstance.Product = data;
	  dialogRef.afterClosed()
    .subscribe(x=>{
       
      this.ngOnInit();
      this.isView = false;
    })
    // this.urlChange.emit(data);
    // this.Product = new ProductModel();
    // this.Product = data;
    // this.Product.amount = 1;
    // localStorage.removeItem("ViewModel");
    // localStorage.setItem("ViewModel" ,JSON.stringify(this.Product))
    //  this.openView = true;
  }
  addQuantity(product) {
    this.Product.amount ++;
  }
  removeQuantity(product: any) {
    if (this.Product.amount > 0) {
      this.Product.amount --;
    }
  }
  selectUnit(product,price:number){
      if(this.sessionService.loggedIn){
          this.productService.ChickGqantity(product.productId,product.amount,0)
          .subscribe(result=>{
            if(result == true){
              this.cartModel.id = product.productId;
              this.cartModel.imageUrl = product.imageUrl;
              this.cartModel.productId = product.productId;
              this.cartModel.quantity = product.amount;
              this.cartModel.totalPrice = price;
              this.cartModel.quantity = 1;
              this.cartModel.productUnits = product.productUnits;
              this.cartModel.productNameAr = product.productNameAr;
              this.cartModel.productNameEn = product.productNameEn;
              this.katoraService.addProductToCart(this.cartModel);
              this.openListOfSize = false;

            }
            else if(result == false){
              this.toastr.error("This product is not available in this branch");
              this.openListOfSize = false;

    
            }
            else{
              window.location.reload();
            }
          },error=>{
            window.location.reload();
    
          })
        }
        else{
          var cityId= localStorage.getItem('CityId')
          if(cityId == null || cityId == undefined || cityId == ''){
            let dialogRef : MatDialogRef<SlectCityComponent>;
            dialogRef =this.matDialog.open(SlectCityComponent,{ disableClose: true });
            dialogRef.afterClosed()
            .subscribe(x=>{
              cityId = x.cityId;
              this.productService.ChickGqantity(product.productId,product.amount,+cityId)
              .subscribe(result=>{
                if(result == true){
                  localStorage.setItem('CityId',cityId.toString());
                  this.cartModel.id = product.productId;
                  this.cartModel.imageUrl = product.imageUrl;
                  this.cartModel.productId = product.productId;
                  this.cartModel.quantity = product.amount;
                  this.cartModel.totalPrice = price;
                  this.cartModel.quantity = 1;
                  this.cartModel.productUnits = product.productUnits;
                  this.cartModel.productNameAr = product.productNameAr;
                  this.cartModel.productNameEn = product.productNameEn;
                  this.katoraService.addProductToCart(this.cartModel);
                  this.openListOfSize = false;

                }
                else if(result == false){
                  this.toastr.error("This product is not available in this branch");
                  this.openListOfSize = false;

    
                }
                else{
                }
              },error=>{
        
              })   
            })
    
          }
        else{
          this.productService.ChickGqantity(product.productId,product.amount,+cityId)
          .subscribe(result=>{
            if(result == true){
              this.cartModel.id = product.productId;
              this.cartModel.imageUrl = product.imageUrl;
              this.cartModel.productId = product.productId;
              this.cartModel.quantity = product.amount;
              this.cartModel.totalPrice = price;
              this.cartModel.quantity = 1;
              this.cartModel.productUnits = product.productUnits;
              this.cartModel.productNameAr = product.productNameAr;
              this.cartModel.productNameEn = product.productNameEn;
              this.katoraService.addProductToCart(this.cartModel);
              this.openListOfSize = false;

            }
            else if(result == false){
              this.toastr.error("This product is not available in this branch");
              this.openListOfSize = false;

    
            }
            else{
            }
          },error=>{
    
          }) 
        }
      
      }
  }
  @HostListener('document:click', ['$event'])
  clickout(event) {
    if(this.eRef.nativeElement.contains(event.target)) {
    } else {
      this.openListOfSize = false;
    }
  }
  SelectedUnitId = 0;
}
