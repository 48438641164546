import { ResetPasswordModel } from './model/reset-password.model';
import { ForgotPasswordModel } from './model/forgot-password.model';
import { ChangePasswordModel } from './model/change-password.model';




// Add the RxJS Observable operators we need in this app.
import { catchError, map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { LoginModel } from './model/login-model';
import { Injectable } from "@angular/core";
import { BaseService } from 'src/app/services/base.service';
import { UserRegistration } from './model/user-registration';
import { SMSModel } from './model/sms.model';

@Injectable({
  providedIn: 'root'
})

export class SessionService extends BaseService {
  baseUrl = environment.DevApiUrl;
  //baseUrl: string = '';
  // Observable navItem source
  private _authNavStatusSource = new BehaviorSubject<boolean>(false);
  // Observable navItem stream
  authNavStatus$ = this._authNavStatusSource.asObservable();

  public loggedIn = false;

  constructor(private http: HttpClient) {
    super();
    this.loggedIn = !!localStorage.getItem('token');
    // ?? not sure if this the best way to broadcast the status but seems to resolve issue on page refresh where auth status is lost in
    // header component resulting in authed user nav links disappearing despite the fact user is still logged in
    this._authNavStatusSource.next(this.loggedIn);
    //this.baseUrl = configService.getApiURI();
  }

//       register(payload: UserRegistration): Observable<UserRegistration> {
//     //let body = JSON.stringify({ email, password, firstName, lastName,location });
//     let headers = new Headers({ 'Content-Type': 'application/json' });
//      let options = new RequestOptions({ headers: headers });

//      const httpOptions = {
//       headers: new HttpHeaders({
//         'Content-Type': 'application/json'
//       })
//     };
//  
//    // return this.http.post(this.baseUrl + "/accounts", body, options)
//    //   .map(res => true)
//    //    .catch(this.handleError);
//    return this.http
//    .post<UserRegistration>(`${this.baseUrl}/Accounts`, payload,httpOptions)
//    .pipe(catchError((error: any) => Observable.throw(error.json())));

//   //  return this.http.post(this.baseUrl +"/accounts", body, httpOptions )
//   //  .map(res =>res.json())
//   //   .catch(this.handleError);
//   } 
  
register(user: UserRegistration): Observable<any> {
 
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/register`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  registerAndSendPassword(user: UserRegistration): Observable<any> {
 
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/registerAndSendPassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  ConfirmRegister(user: UserRegistration): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ConfirmRegister`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  changePassword(user: ChangePasswordModel): Observable<any> {
  const token = localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Authorization": "Bearer " + token,
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ChangePassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  ForgotPassword(user: ForgotPasswordModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ForgotPassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  resetPassword (user: ResetPasswordModel): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http
      .post(`${this.baseUrl}/Account/ResetPassword`, user, httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  // login(user: LoginModel): Observable<any> {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   return this.http
  //     .post(`${this.baseUrl}/Auth`, user, httpOptions)
  //     .pipe(map=>{

  //     }
        
  //       catchError((error: any) => Observable.throw(error.json())));
  // }

  login(user: LoginModel) {
    const httpOptions = {
           headers: new HttpHeaders({
            'Content-Type': 'application/json'
           })
        };
    return this.http.post<any>(`${this.baseUrl}/account/token`, user,httpOptions)
        .pipe(map( user => {
            if (user) {
              localStorage.setItem('token', user.token);
              this.loggedIn = true;
              this._authNavStatusSource.next(true);
              localStorage.setItem('currentUser', JSON.stringify(user));
              return true;
            }
            else{
              return false;
            }
        })
        );
}

SMSMessage="Welcome to Laderach. To activate your account ,please enter the following code: ";
 smsModel:SMSModel={};
sendSMS(code: number,mobileNumber:string) {

 
  // var url = `https://api.gateway.sa/api/v2/SendSMS?SenderId=${environment.SMSSenderId}&Message=${this.SMSMessage+code.toString()}&MobileNumbers=${mobileNumber}&ApiKey=${environment.SMSAPIKey}&ClientId=${environment.SMSClientId}`;
  var url = `https://api.gateway.sa/api/v2/SendSMS?SenderId=${environment.SMSSenderId}&Message=${this.SMSMessage.toString()+code.toString()}&MobileNumbers=${mobileNumber}&ApiKey=${environment.SMSAPIKey}&ClientId=${environment.SMSClientId}`
  return this.http.get<any>(url)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
}
// sendSMS(code: number,mobileNumber:string) {
//   const httpOptions = {
//     headers: new HttpHeaders({
//      'Content-Type': 'application/json'
//     })
//  };
//  this.smsModel = new SMSModel();
//  this.smsModel.apiKey = environment.SMSAPIKey;
//  this.smsModel.senderId = environment.SMSSenderId;
//  this.smsModel.clientId = environment.SMSClientId;
//  this.smsModel.message = this.SMSMessage+code.toString();
//  this.smsModel.mobileNumber = mobileNumber
//   var url = `https://api.gateway.sa/api/v2/SendSMS`;
//   return this.http.post<any>(url,this.smsModel,httpOptions)
//           .pipe(catchError((error: any) => Observable.throw(error.json())));
// }



  


  //  login(user: UserRegistration) {
  //   let headers = new Headers();
  //   headers.append('Content-Type', 'application/json');
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //   return this.http
  //   .post(`${this.baseUrl}/Auth`, user, httpOptions)
  //     .map(res => {
  //       localStorage.setItem('auth_token', res.auth_token);
  //       this.loggedIn = true;
  //       this._authNavStatusSource.next(true);
  //       return true;
  //     })
  //     .catch(this.handleError);
  // }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentUserSettings');
    
    this.loggedIn = false;
    this._authNavStatusSource.next(false);
    
  }

  isLoggedIn() {
    return this.loggedIn;
  }

  facebookLogin(accessToken:string) {
    const httpOptions = {
           headers: new HttpHeaders({
            'Content-Type': 'application/json'
           })
        };
        let body = JSON.stringify({ accessToken });  
    return this.http.post<any>(`${this.baseUrl}/externalauth/facebook`, body,httpOptions)
        .pipe(map(user => {
            if (user) {
              var myUser = JSON.parse(user);
              localStorage.setItem('auth_token', myUser.auth_token);
              this.loggedIn = true;
              this._authNavStatusSource.next(true);
              localStorage.setItem('currentUser', JSON.stringify(user));
              return true;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                //localStorage.setItem('currentUser', JSON.stringify(user));
               

            }

            return true;
        })
        );
}


  //  facebookLogin(accessToken:string) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/json'
  //     })
  //   };
  //    let headers = new Headers();
  //    headers.append('Content-Type', 'application/json');
  //    let body = JSON.stringify({ accessToken });  
  //    return this.http
  //      .post(
  //      this.baseUrl + '/externalauth/facebook', body, httpOptions)
  //      .map(res => res.json())
  //      .map(res => {
  //        localStorage.setItem('auth_token', res.auth_token);
  //        this.loggedIn = true;
  //        this._authNavStatusSource.next(true);
  //        return true;
  //      })
  //      .catch(this.handleError);
  //  }


  roleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    allowedRoles.forEach(element => {
      if (userRole == element) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  CheckroleMatch(allowedRoles): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    var userRole = payLoad.role;
    if(userRole==allowedRoles){
      return true;
    }
    else
    return false
  }

  GetUserFullNameName(): boolean {
    var isMatch = false;
    var payLoad = JSON.parse(window.atob(localStorage.getItem('token').split('.')[1]));
    return payLoad.Email;
  
  }
}

