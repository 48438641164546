import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BoxCartModel } from '../user-account/models/box-cart.model';
@Injectable({
  providedIn: 'root'
})
export class BoxCartService {
  public BoxCartProducts = [];
  public wishlistProducts = [];
  public count;
  baseUrl = environment.DevApiUrl;
   token = localStorage.getItem('token');
   httpOptions = {
    headers: new HttpHeaders({
      "Authorization": "Bearer " + this.token,
      "Content-Type": "application/json"
    })
  };
  constructor(private http: HttpClient, private toastr: ToastrService ) {
 
  //   const token = localStorage.getItem('token');
  //   if (token != null) {
  //   this.GetCount()
  //   .subscribe(data => {
  //     localStorage.setItem('BoxCartCount' , data.toString());
  //   });
  //   localStorage.removeItem('BoxCartProducts');
  //   this.GetForCurrentUser()
  //   .subscribe(data => {
  //     data.forEach(element => {
  //       this.addProductToBoxCart(element);
  //     });
  //   });
  // }
  }
  getProductFromBoxCart() {
    if (localStorage.getItem('BoxCartProducts') == null) {
      return [];
    }
    else
      return JSON.parse(localStorage.getItem('BoxCartProducts'));
  }
  addProductToBoxCart(products: any) {
    let count = 0;
    this.BoxCartProducts.forEach(element => {


      if (products.id == element.id) {
        count++;
      }


    });
    if (count == 0) {
      if (products.quantity <= 0) {
        products.quantity = 1;
      }
      this.BoxCartProducts.push(products);
      this.updateBoxCartProduct();
    }
  }
  removeProductFromBoxCart(products: any) {
 
    let count = 0;
    this.BoxCartProducts.forEach((element) => {
      if (products.id == element.id) {
        const i =this.BoxCartProducts.findIndex(x => x.id == products.id);
        this.BoxCartProducts.splice(i, 1);
        count++;
      }
    });
    if (count != 0) {
      this.updateBoxCartProduct();
    }
  }
  updateBoxCartProduct() {
    localStorage.setItem('BoxCartProducts', JSON.stringify(this.BoxCartProducts));
  }
  GetForCurrentUser(): Observable<BoxCartModel[]> {
    return this.http
      .get<BoxCartModel[]>(`${this.baseUrl}/BoxCart/GetForCurrentUser`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetByOrderId(orderId: number): Observable<BoxCartModel[]> {
    return this.http
      .get<BoxCartModel[]>(`${this.baseUrl}/BoxCart/GetByOrderId/${orderId}`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  GetCount(): Observable<number> {
    return this.http
      .get<number>(`${this.baseUrl}/BoxCart/GetCount`, this.httpOptions)
      .pipe(catchError((error: any) => Observable.throw(error.json())));
  }
  createBoxCart(payload: BoxCartModel): Observable<number> {
     
        return this.http
          .post<number>(`${this.baseUrl}/BoxCart/Insert`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
      
InsertList(payload: BoxCartModel[]): Observable<boolean> {
   return this.http
     .post<boolean>(`${this.baseUrl}/BoxCart/InsertList`, payload, this.httpOptions)
     .pipe(catchError((error: any) => Observable.throw(error.json())));
 }
 InsertListAsAGuest(payload: BoxCartModel[]): Observable<boolean> {
  return this.http
    .post<boolean>(`${this.baseUrl}/BoxCart/InsertListAsAGuest`, payload, this.httpOptions)
    .pipe(catchError((error: any) => Observable.throw(error.json())));
}
 
 put(payload: BoxCartModel): Observable<boolean> {
        return this.http
          .put<boolean>(`${this.baseUrl}/BoxCart/Update`, payload, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
 delete(payload: BoxCartModel): Observable<boolean> {
        return this.http
          .get<boolean>(`${this.baseUrl}/BoxCart/Delete/${payload.id}`, this.httpOptions)
          .pipe(catchError((error: any) => Observable.throw(error.json())));
      }
}
