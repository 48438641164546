import { Input } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { ProductImageModel } from 'src/app/pages/shop/models/product-image.model';
import { ProductModel } from 'src/app/pages/shop/models/Product.model';
import { CartModel } from 'src/app/pages/user-account/models/Cart.model';
import { KatoraService } from 'src/app/services/katora.service';
import { CartDropdownComponent } from '../cart-dropdown/cart-dropdown.component';
import { SessionService } from 'src/app/pages/session/session.service';
import { ProductService } from 'src/app/pages/shop/product.service';
import { SlectCityComponent } from '../slect-city/slect-city.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})
export class ProductModalComponent implements OnInit {
  @Input()
  Product: ProductModel = {};
  images: ProductImageModel[] = [];
  cartModel: CartModel = {};
  wishList: any = {};
  productPrice = 0;
  openModel = false;
  cartDropdownComponent: CartDropdownComponent;
  public current_language;
  constructor(
    public translate: TranslateService,
    private katoraService: KatoraService,
    public dialogRef : MatDialogRef<ProductModalComponent>,
    private matDialog : MatDialog,
    private sessionService:SessionService,
    private productService:ProductService,
    private toastr: ToastrService,
     ) {
    this.current_language = localStorage.getItem('lang');

     }
     closeModel(){
      localStorage.removeItem("ViewModel");
      this.Product = {};
      this.dialogRef.close()
     }
  ngOnInit() {
    if(this.Product.price == null){
      this.productPrice = 0;
    }
    else{
      this.productPrice = this.Product.price;
    }
    this.Product.amount = 1;
    this.images = this.Product.images;
    this.openModel = true;
  }
  onAddWishlist(product: any) {
    this.wishList.id = product.productId;
    this.wishList.productId = product.productId;
    this.wishList.quantity = product.amount;
    this.wishList.imageUrl = product.imageUrl;
    this.wishList.productNameEn = product.productNameEn;
    this.wishList.productNameAr = product.productNameAr;
    this.wishList.totalPrice = product.price;
    this.wishList.descriptionEn = product.smallDescriptionEn;
    this.wishList.descriptionAr = product.smallDescriptionAr;
    this.katoraService.addProductToWishlist(this.wishList);
  }
  onAddCart(product: any) {
    if(this.sessionService.loggedIn){
      this.productService.ChickGqantity(product.productId,product.amount,0)
      .subscribe(result=>{
        if(result == true){
          this.cartModel.id = product.productId;
          this.cartModel.imageUrl = product.imageUrl;
          this.cartModel.productId = product.productId;
          this.cartModel.quantity = product.amount;
          this.cartModel.totalPrice = product.price;
          this.cartModel.quantity = 1;
          this.cartModel.productUnits = product.productUnits;
          this.cartModel.productNameAr = product.productNameAr;
          this.cartModel.productNameEn = product.productNameEn;
          this.katoraService.addProductToCart(this.cartModel);
          this.dialogRef.close();
        }
        else if(result == false){
          this.toastr.error("This product is not available in this branch");
          this.dialogRef.close();

        }
        else{
          window.location.reload();
        }
      },error=>{
        window.location.reload();

      })
    }
    else{
      var cityId= localStorage.getItem('CityId')
      if(cityId == null || cityId == undefined || cityId == ''){
        let dialogRef : MatDialogRef<SlectCityComponent>;
        dialogRef =this.matDialog.open(SlectCityComponent,{ disableClose: true });
        dialogRef.afterClosed()
        .subscribe(x=>{
          cityId = x.cityId;
          this.productService.ChickGqantity(product.productId,product.amount,+cityId)
          .subscribe(result=>{
            if(result == true){
              localStorage.setItem('CityId',cityId.toString());
              this.cartModel.id = product.productId;
              this.cartModel.imageUrl = product.imageUrl;
              this.cartModel.productId = product.productId;
              this.cartModel.quantity = product.amount;
              this.cartModel.totalPrice = product.price;
              this.cartModel.quantity = 1;
              this.cartModel.productUnits = product.productUnits;
              this.cartModel.productNameAr = product.productNameAr;
              this.cartModel.productNameEn = product.productNameEn;
              this.katoraService.addProductToCart(this.cartModel);
              this.dialogRef.close();
            }
            else if(result == false){
              this.toastr.error("This product is not available in this branch");
              this.dialogRef.close();

            }
            else{
              window.location.reload();
            }
          },error=>{
            window.location.reload();
    
          })   
        })

      }
    else{
      this.productService.ChickGqantity(product.productId,product.amount,+cityId)
      .subscribe(result=>{
        if(result == true){
          this.cartModel.id = product.productId;
          this.cartModel.imageUrl = product.imageUrl;
          this.cartModel.productId = product.productId;
          this.cartModel.quantity = product.amount;
          this.cartModel.totalPrice = product.price;
          this.cartModel.quantity = 1;
          this.cartModel.productUnits = product.productUnits;
          this.cartModel.productNameAr = product.productNameAr;
          this.cartModel.productNameEn = product.productNameEn;
          this.katoraService.addProductToCart(this.cartModel);
          this.dialogRef.close();
        }
        else if(result == false){
          this.toastr.error("This product is not available in this branch");
          this.dialogRef.close();

        }
        else{
          window.location.reload();
        }
      },error=>{
        window.location.reload();

      }) 
    }
    // this.cartModel.productPrice = product.productPrice;
    // this.cartService.createCart(this.cartModel)
    // .subscribe(result => {
    //   if (result > 0) {
    //     this.toaster.success('Product Added To Cart');
    //     let count = + localStorage.getItem('cartCount');
    //     count ++;
    //     localStorage.removeItem('cartCount');
    //     localStorage.setItem('cartCount', count.toString());
    //     this.cartModel.id = result;
    //     let products = JSON.parse(localStorage.getItem('cartProducts'));
    //     const i = products.findIndex(x => x.id === this.cartModel.id);
    //     if (i === -1) {
    //       product.totalPrice = product.price;
    //       product.quantity = 1;
    //       products.push(product);
    //       localStorage.setItem('cartProducts' , JSON.stringify(products));
    //     }
    //   }
    // });
  }

  }
  addQuantity(product) {
    this.Product.amount ++;
  }
  removeQuantity(product: any) {
    if (this.Product.amount > 0) {
      this.Product.amount --;
    }
  }
}
