import { GivingGiftModel } from './../../pages/gift-cards/models/giving-gift.model';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'view-cift-in-email',
    templateUrl: 'view-cift-in-email.component.html',
    styleUrls: ['view-cift-in-email.component.scss']
})
export class ViewCiftInEmailComponent implements OnInit {
    @Input()
    data: GivingGiftModel = {};
    gift:any={};
    public current_language = 'ar';
    constructor(
      public translate: TranslateService,
      public dialogRef : MatDialogRef<ViewCiftInEmailComponent>
       ) {
      this.current_language = localStorage.getItem('lang');
  
       }
    ngOnInit(): void {
    }
}
